import { Model } from '@vuex-orm/core';
import TechnologistOrderAPI from '@/api/TechnologistOrderAPI';

export default class TechnologistOrder extends Model {
    public static entity = 'technologistOrder';

    public static fields() {
        return {
            id: this.string(''),
            documentType: this.string(''),
            numOfOrders: this.number(0),
            orderValue: this.string(''),
            installationPrice: this.string(''),
            comment: this.string(''),
            invoicedDate: this.string(''),
            orderAcceptedDate: this.string(''),
            manufacturedDate: this.string(''),
            abConfirmedDate: this.string(''),
            deliveryDate: this.string(''),
            postedDate: this.string(''),
            inProduction: this.string(''),
            technologistDate: this.string(''),
            prepaymentAcceptedDate: this.string(''),
            finalDate: this.string(''),
            distributionDate: this.string(''),
            serviceCompletedDate: this.string(''),
        };
    }

    public static async getById(technologistOrderId: string) {
        let technologistOrder;

        try {
            technologistOrder = await TechnologistOrderAPI.getById(technologistOrderId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: technologistOrder.data,
        });

        return Promise.resolve(technologistOrder);
    }

    public static async updateExisting(technologistOrderId: string, technologistOrderInfo: any, offerId: string) {
        let technologistOrder;

        try {
            technologistOrder = await TechnologistOrderAPI.update(technologistOrderId, technologistOrderInfo, offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: technologistOrder.data,
        });

        return Promise.resolve(technologistOrder);
    }

    public static async createNew(technologistOrderInfo: any, offerId: string, currentUserId: string) {
        let technologistOrder;

        try {
            technologistOrder = await TechnologistOrderAPI.create(technologistOrderInfo, offerId, currentUserId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({
            data: technologistOrder.data,
        });

        return Promise.resolve(technologistOrder);
    }

    public static async deleteExisting(technologistOrderId: string) {
        try {
            await TechnologistOrderAPI.delete(technologistOrderId);
        } catch (e) {
            return Promise.reject(e);
        }

        await TechnologistOrder.delete(technologistOrderId);
        return Promise.resolve();
    }
    public id!: string;
    public name!: string;
    public documentType!: string;
    public numOfOrders!: number;
    public orderValue!: string;
    public installationPrice!: string;
    public comment!: string;
    public invoicedDate!: string;
    public orderAcceptedDate!: string;
    public manufacturedDate!: string;
    public abConfirmedDate!: string;
    public deliveryDate!: string;
    public postedDate!: string;
    public inProduction!: string;
    public technologistDate!: string;
    public prepaymentAcceptedDate!: string;
    public finalDate!: string;
    public distributionDate!: string;
    public serviceCompletedDate!: string;
}
