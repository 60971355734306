
import { Component, Prop, Vue } from 'vue-property-decorator';
import ColorModuleSearch from '@/components/global/color-module/ColorModuleSearch.vue';
import ColorModuleFilters from '@/components/global/color-module/ColorModuleFilters.vue';

@Component({
    name: 'ColorModuleHeader',
    components: {
        ColorModuleSearch,
        ColorModuleFilters,
    },
})
export default class ColorModuleHeader extends Vue {
    @Prop({ required: false, default: false }) private activeTab!: string;
    @Prop({ required: true }) private filters!: any;
    @Prop({ required: true }) private filtersActive!: boolean;
    @Prop({ required: true }) private requestFiltersActive!: boolean;
    @Prop({ required: true }) private searchQuery!: string;

    // private tooltipVisible: boolean = false;

    private setFilters(active: boolean) {
        this.$emit('setFiltersActiveState', active);
    }

    private clearFilters() {
        this.$emit('clearFilters');
    }

    private requestTabClick() {
        // this.tooltipVisible = false;
        this.$emit('requestTabClick', 'color-request');
        this.$notification.info({
            message: this.$t('Click on Color Search to go back to searching mode.') as string,
            description: '',
        });
    }

    // private onTooltipHover() {
    //     this.tooltipVisible = true;
    // }

    // private onTooltipBlur() {
    //     this.tooltipVisible = false;
    // }

    private get filterActiveState() {
        if (this.activeTab === 'color-request') {
            return this.requestFiltersActive;
        }

        return this.filtersActive;
    }
}
