import { Model } from '@vuex-orm/core';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import OfferItemAPI from '@/api/OfferItemAPI';
import Offer from '@/models/Offer';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import ProductCatalogue from '@/models/ProductCatalogue';
import ProductSystem from '@/models/ProductSystem';
import { OfferItemRowNumber } from '@/interfaces/components/ProductsInOffer/OfferItemRowNumber';
import OfferItemPriceManipulation from '@/models/OfferItemPriceManipulation';
import OfferItemPrice from '@/models/OfferItemPrice';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { MultipositionProduct } from '@/interfaces/components/projectNew/MultipositionProduct';
export default class OfferItem extends Model {
    public get offerItemFormData() {
        // @ts-ignore
        return this.formData;
    }

    public get offerItemId() {
        // @ts-ignore
        return this.id;
    }

    public get offerItemName() {
        // @ts-ignore
        return this.name;
    }

    public get offerItemProductSystem() {
        // @ts-ignore
        return this.productSystem;
    }

    public get offerItemOfferId() {
        // @ts-ignore
        return this.offer_id;
    }

    public get offerItemProductSystemId() {
        // @ts-ignore
        return this.productSystem_id;
    }

    public get offerItemOfferItemType() {
        // @ts-ignore
        return this.offerItemType;
    }

    public get offerItemMaterial() {
        // @ts-ignore
        return this.material;
    }

    public get offerItemMaterialId() {
        // @ts-ignore
        return this.material_id;
    }

    public get offerItemProductDetails() {
        // @ts-ignore
        return this.productDetails;
    }

    public static entity = 'offerItem';

    public static fields() {
        return {
            id: this.attr(null),
            formData: this.attr([]),
            offerItemType: this.string(''),
            createdAt: this.string(''),
            name: this.string(''),
            displayRowNumber: this.number(0),
            updatedAt: this.string(''),
            rowNumber: this.number(0),
            offer_id: this.attr(null),
            productSystem_id: this.attr(null),
            productSystem: this.belongsTo(ProductSystem, 'productSystem_id'),
            material_id: this.attr(null),
            material: this.belongsTo(ProductCatalogue, 'material_id'),
            // offer: this.belongsTo(Offer, 'offer_id'),
            sellingPriceManipulation_ids: this.attr([]),
            sellingPriceManipulations: this.hasManyBy(OfferItemPriceManipulation, 'sellingPriceManipulation_ids'),
            sellingPriceManipulationDetails: this.attr([]),
            purchasePriceManipulation_ids: this.attr([]),
            purchasePriceManipulations: this.hasManyBy(OfferItemPriceManipulation, 'purchasePriceManipulation_ids'),
            purchasePriceManipulationDetails: this.attr([]),
            roltekPriceManipulation_ids: this.attr([]),
            roltekPriceManipulations: this.hasManyBy(OfferItemPriceManipulation, 'roltekPriceManipulation_ids'),
            roltekPriceManipulationDetails: this.attr([]),
            productDetails: this.attr(null),
            offerItemPrice_id: this.attr(null),
            offerItemPrice: this.belongsTo(OfferItemPrice, 'offerItemPrice_id'),
            priceListVersion: this.attr(null),
            checklistVersion: this.attr(null),
            production: this.attr(false),
            connection: this.attr(null),
            hasErrors: this.boolean(false),
            allowErrors: this.boolean(false),
            validWarranty: this.boolean(false),
        };
    }

    public static async createNew(
        activeProductFormValues: ActiveProductFormValueObject,
        offerId: string,
        hasChecklistErrors: boolean,
        connection: null | number = null,
        production = false,
        errorsHaveValidWarranty: boolean | null = null,
        productFormId: number | null
    ) {
        let offerItem;
        try {
            // tslint:disable-next-line:max-line-length
            offerItem = await OfferItemAPI.createNew(
                activeProductFormValues,
                offerId,
                hasChecklistErrors,
                connection,
                production,
                errorsHaveValidWarranty,
                productFormId
            );
        } catch (e) {
            return Promise.reject(e);
        }

        // await this.insertOrUpdate({
        //     data: offerItem.data,
        // });

        return Promise.resolve(offerItem);
    }

    public static async createMaterial(
        activeProductFormValues: ActiveProductFormValueObject,
        productCatalogueId: string,
        offerId: string,
        connection: number | null,
        production: boolean
    ) {
        let offerItem;

        try {
            offerItem = await OfferItemAPI.createMaterial(
                activeProductFormValues,
                productCatalogueId,
                offerId,
                connection,
                production
            );
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offerItem.data,
            insertOrUpdate: ['offer', 'offerItems'],
        });

        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
        return Promise.resolve();
    }

    public static async createNewBulk(payload: MultipositionProduct[], offerId: string) {
        let offerItem;

        try {
            offerItem = await OfferItemAPI.createNewBulk(payload, offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        // await this.insertOrUpdate({
        //     data: offerItem.data,
        // });

        return Promise.resolve();
    }

    public static async updateNewBulk(payload: MultipositionProduct[], offerId: string) {
        let offerItem;

        try {
            offerItem = await OfferItemAPI.updateNewBulk(payload, offerId);
        } catch (e) {
            return Promise.reject(e);
        }

        // await this.insertOrUpdate({
        //     data: offerItem.data,
        // });

        return Promise.resolve();
    }

    public static async deleteExisting(offerItemId: string) {
        await OfferItem.delete(offerItemId);
        try {
            await OfferItemAPI.delete(offerItemId);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async getById(offerItemId: string) {
        let offerItem;
        try {
            offerItem = await OfferItemAPI.getById(offerItemId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offerItem.data,
            insertOrUpdate: ['productSystem, offer'],
        });

        return Promise.resolve();
    }

    public static async updateExisting(
        formData: ChecklistFieldEntry[],
        offerItemId: string,
        offerItemType: string,
        hasChecklistErrors: boolean,
        productFormId: number | null,
        connection: null | number = null,
        production = false,
        allowErrors: boolean | null = null,
        errorsHaveValidWarranty: boolean | null = null
    ) {
        let offerItem;

        try {
            offerItem = await OfferItemAPI.updateExisting(
                offerItemId,
                formData,
                offerItemType,
                connection,
                production,
                hasChecklistErrors,
                allowErrors,
                errorsHaveValidWarranty,
                productFormId
            );
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offerItem.data,
            insertOrUpdate: ['productSystem, offer,material'],
        });

        return Promise.resolve();
    }

    public static async updateAllowErrorState(id: string, state: boolean, productFormId: number) {
        let offerItem;

        try {
            offerItem = await OfferItemAPI.updateAllowErrorState(id, state, productFormId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offerItem.data,
        });

        return Promise.resolve();
    }

    public static async updateAllowWarrantyState(id: string, state: boolean, productFormId: number) {
        let offerItem;

        try {
            offerItem = await OfferItemAPI.updateAllowWarrantyState(id, state, productFormId);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: offerItem.data,
        });

        return Promise.resolve();
    }

    public static async duplicateExisting(offerItemId: string) {
        let offerItem;
        try {
            offerItem = await OfferItemAPI.duplicateExisting(offerItemId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: offerItem.data,
            insertOrUpdate: ['productSystem, offer,material'],
        });

        return Promise.resolve();
    }

    public static async updateRowNumbers(offerItemRowNumbers: OfferItemRowNumber[]) {
        try {
            await OfferItemAPI.updateRowNumbers(offerItemRowNumbers);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
    public id!: string;
    public rowNumber!: number;
    public displayRowNumber!: number;
    public name!: string;
    public productSystem!: ProductSystem;
    public sellingPriceManipulations!: OfferItemPriceManipulation[];
    public sellingPriceManipulationDetails!: any[];
    public purchasePriceManipulations!: OfferItemPriceManipulation[];
    public purchasePriceManipulationDetails!: any[];
    public roltekPriceManipulations!: OfferItemPriceManipulation[];
    public roltekPriceManipulationDetails!: any[];
    public offerItemPrice!: OfferItemPrice;
    public priceListVersion!: string | null;
    public checklistVersion!: string | null;
    public production!: boolean;
    public connection!: number;
    public hasErrors!: boolean;
    public allowErrors!: boolean;
    public validWarranty!: boolean;
    public formData!: any[];
    public offerItemType!: string;
}
