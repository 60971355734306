import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';

export default class TechnologistOrderAPI {
    public static async getById(technologistOrderId: string) {
        let technologistOrder;

        try {
            technologistOrder = await ApiController.get(ApiRoutes.technologist.get(technologistOrderId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(technologistOrder);
    }

    public static async update(
        technologistOrderId: string,
        technologistOrderData: AvailableLabelFields,
        offerId: string
    ) {
        let technologistOrder;
        const request = {
            data: {
                type: 'technologist_orders',
                id: technologistOrderId,
                attributes: {
                    name: technologistOrderData.name,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerId,
                        },
                    },
                },
            },
        };

        try {
            technologistOrder = await ApiController.patch(ApiRoutes.technologist.update(technologistOrderId), request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(technologistOrder);
    }

    public static async create(technologistOrderData: AvailableLabelFields, offerId: string, currentUserId: string) {
        let technologistOrder;
        const request = {
            data: {
                type: 'technologist_orders',
                attributes: {
                    ...technologistOrderData,
                },
                relationships: {
                    offer: {
                        data: {
                            type: 'offers',
                            id: offerId,
                        },
                    },

                    // technologist: {
                    //     data: {
                    //         type: 'users',
                    //         id: null,
                    //     },
                    // },
                },
            },
        };

        console.log(technologistOrderData);

        try {
            technologistOrder = await ApiController.post(ApiRoutes.technologist.create, request);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(technologistOrder);
    }

    public static async delete(technologistOrderId: string) {
        try {
            await ApiController.delete(ApiRoutes.technologist.delete(technologistOrderId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }
}
