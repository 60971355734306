
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'ColorModuleResult',
    components: {},
})
export default class ColorModuleResult extends Vue {
    @Prop({ required: true }) private requestResult!: string;

    private handleSubmit() {
        this.$emit('sendRequest');
    }
}
