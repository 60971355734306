import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';

const actions: ActionTree<ILocalState, {}> = {
    setColorsData({ commit }, colorsData) {
        for (const key in colorsData) {
            switch (key) {
                case 'colors':
                    commit('setColors', colorsData[key]);
                    break;
                case 'powders':
                    commit('setPowders', colorsData[key]);
                    break;
                case 'prices':
                    commit('setPrices', colorsData[key]);
                    break;
                case 'surfaces':
                    commit('setSurfaces', colorsData[key]);
                    break;
                case 'qualities':
                    commit('setQualities', colorsData[key]);
                    break;
                case 'glosses':
                    commit('setGlosses', colorsData[key]);
                    break;
                case 'effects':
                    commit('setEffects', colorsData[key]);
                    break;
                case 'charts':
                    commit('setCharts', colorsData[key]);
                    break;
                case 'collections':
                    commit('setCollections', colorsData[key]);
                    break;
            }
        }
    },
};

export default actions;
