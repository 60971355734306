
import { Component } from 'vue-property-decorator';
import HomeUpperSection from '@/components/views/home/HomeUpperSection.vue';
import ProjectsTableWrapper from '@/components/global/table-wrappers/ProjectsTableWrapper.vue';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import TableSearchBar from '@/components/global/TableSearchBar.vue';
import {
  generateSearchMessage,
  prepareAndPrintTable,
} from '@/helpers/TableHelper';
import i18n from '@/i18n';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import store from '@/store';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { RouteNames } from '@/enums/routes/RouteNames';

@Component({
  name: 'ActivitiesProjectsModal',
  components: {
    TableSearchBar,
    HomeUpperSection,
    ProjectsTableWrapper,
  },
})
export default class ActivitiesScreen extends mixins<UserRightsMixin>(
    UserRightsMixin,
) {
  private entityType = ProjectTypes.All;
  private search = {
    isActive: true,
  };
  private numberOfTotalItems = 0;
  private loadingOverlay = new LoadingOverlayHelper(this, {});

  private created() {
    EventBus.$on(EventBusEvents.printActivities, (payload: any) => {
      this.onPrint();
    });
  }

  private get searchMessage() {
    return generateSearchMessage(this.numberOfTotalItems);
  }

  private get currentUser() {
    return UserRepository.getCurrentUser();
  }

  private onUpdatePagination({ totalNumber }: { totalNumber: number }) {
    if (totalNumber == null) {
      return;
    }

    this.numberOfTotalItems = totalNumber;
  }

  private async onPrint() {
    if (this.currentUser == null || this.entityType == null) {
      return;
    }

    this.startLoaderAndNotifyUserForPrinting();
    // @ts-ignore
    await prepareAndPrintTable(this.entityType as string, this.currentUser.id, this.$htmlToPaper);

    this.loadingOverlay.stop();
  }

  private startLoaderAndNotifyUserForPrinting() {
    this.loadingOverlay.start();
    this.$notification.info({
      message: i18n.t('Generiranje liste projekata je započeto') as string,
      description: i18n.t(
          'Molimo za strpljenje i da ne gasite ovu karticu',
      ) as string,
    });
  }

  private beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    const userRights = store.getters['jwtData/userRights'];
    const canSeeAllNextSteps = userRights.includes(
        UserRightsEnum.VIEW_ALL_NEXT_STEPS,
    );

    if (canSeeAllNextSteps) {
      next();
    } else {
      next({
        name: RouteNames.home,
      });
    }
  }

  private startTableLoading() {
    const wrapper = (this.$refs.projectTableWrapper as any);
    if(wrapper && wrapper.startLoading) {
      wrapper.startLoading();
    }
  }

  private async mounted() {
    EventBus.$on(EventBusEvents.emitProjectPagination, this.onUpdatePagination);
  }

  private beforeDestroy() {
    EventBus.$off(
        EventBusEvents.emitProjectPagination,
        this.onUpdatePagination,
    );
  }
}
