import Store from '@/store';
import { ColorRequestConfig } from '@/interfaces/components/color/ColorRequestConfig';
import { ColorFilters } from '@/interfaces/components/color/ColorFilters';

export const FiltersConfiguration = [
    {
        options: () => Store.getters['colorData/charts'],
        label: 'Color system',
        type: 'colorSystem',
        filter: 'colorChart',
        fieldType: 'dropdown',
        isMandatory: true,
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/collections'],
        label: 'Color collection',
        type: 'colorCollection',
        filter: 'defaultPowder.colorCollection',
        fieldType: 'dropdown',
        isMandatory: true,
        defaultValue: '1',
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/surfaces'],
        label: 'Gloss/Surface',
        type: 'colorSurface',
        filter: 'colorSurface',
        fieldType: 'dropdown',
        isMandatory: false,
        info: 'Defines the durability and resistance of the color, including factors like weather resistance and longevity.',
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/effects'],
        label: 'Effect',
        type: 'colorEffect',
        filter: 'colorEffect',
        fieldType: 'dropdown',
        isMandatory: false,
        info: 'Describes special visual properties of the color, such as metallic, pearl, or structured finishes.',
        isActive: () => true,
    },
    {
        options: () => Store.getters['colorData/qualities'],
        label: 'Quality',
        type: 'colorQuality',
        filter: 'colorQuality',
        fieldType: 'dropdown',
        isMandatory: false,
        info: 'Indicates the level of gloss (e.g., matte, semi-gloss, high-gloss) and texture of the coating.',
        isActive: () => true,
    },
];

export const formatCustomColor = function (colorConfig: ColorRequestConfig, colorFilters: ColorFilters) {
    const values = [
        colorConfig?.colorName,
        colorConfig?.colorProducer,
        colorFilters?.colorSystem?.value,
        colorConfig?.powderID,
        colorFilters?.colorCollection?.value,
        colorFilters?.colorGloss?.value,
        colorFilters?.colorSurface?.value,
        colorFilters?.colorEffect?.value,
        colorFilters?.colorQuality?.value,
    ];

    return values.filter((value) => value).join(' | ');
};

export const formatColorSearchQuery = function (filters: ColorFilters, searchText: string = '') {
    let query = '';

    if (searchText) {
        query += `&search=${searchText}`;
    }

    if (filters) {
        for (const key in filters) {
            if (!filters[key as keyof ColorFilters]?.id) {
                continue;
            }

            if (filters[key as keyof ColorFilters]) {
                const filterConfig = FiltersConfiguration.find((filter) => filter.type === key);
                const prefix = filterConfig?.isMandatory ? '' : 'powders.';

                query += `&filter[${prefix}${filterConfig?.filter}.id]=${filters[key as keyof ColorFilters]?.id}`;
            }
        }
    }

    return query;
};

export const formatSelectedColorForCustomRequest = function (selectedColor: any) {
    const { name, externalId, powders } = selectedColor;
    const powder = powders?.[0];
    const powderExternalId = powder?.externalId;
    const materialOid = powder?.material?.oid;

    return [name, externalId, powderExternalId, materialOid].filter(Boolean).join(' | ');
};
