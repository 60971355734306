
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { RouteNames } from '@/enums/routes/RouteNames';
import { Action, Getter, Mutation } from 'vuex-class';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import {
    extractDefaultValues,
    extractUpdateFunctions,
    cssMultipositionRowElementPositioning,
} from '@/helpers/FormDataHelper';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import ConfiguratorService from '@/services/ConfiguratorService';
import ProductSlotRow from '@/components/global/popup/ProductSlotRow.vue';
import { UpdateFunctionEntry } from '@/interfaces/components/configurator/UpdateFunctionEntry';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';
import { ChecklistField } from '@/interfaces/components/configurator/ChecklistField';
import AddOfferItems from '@/components/global/popup/AddOfferItems.vue';
import { MultipositionDeleteMap } from '@/interfaces/general/MultipositionDeleteMap';
import { capitalizeWord } from '@/helpers/TranslationHelper';
import { debounce } from 'vue-debounce';
import SegmentedMultiposition from '@/components/views/project/SegmentedMultiposition.vue';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import PopupNotifications from '@/components/global/popup/PopupNotifications.vue';
import { PopupNotificationOptions } from '@/interfaces/components/projectNew/PopupNotificationOptions';
import { checkForErrorsInFieldsAndReturnState, resetHelperState } from '@/helpers/FormValidationHelper';
import { FormValidationPayload } from '@/interfaces/general/FormValidationPayload.js';

@Component({
    name: 'AddPopupProductSlot',
    components: {
        AddOfferItems,
        ProductSlotRow,
        SegmentedMultiposition,
        PopupNotifications,
    },
})
export default class AddPopupProductSlot extends Vue {
    @Prop({ required: true }) private popupData!: any;
    @Action('configurator/getSingleProductForm') private getSingleProductForm!: (productFormId: string) => void;
    @Getter('configurator/productForm') private productForm!: (productFormId: string) => ProductForm | null;
    @Action('configurator/addActiveProductForm')
    private addActiveProductForm!: (activeProductForm: ActiveProductFormValueObject) => Promise<number | null>;
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;
    @Mutation('configurator/resetActiveProductForms') private resetActiveProductForms!: () => void;
    @Getter('configurator/activeProductForm')
    private getProductFormValues!: (productFormId: number) => ActiveProductFormValueObject | null;
    @Getter('configurator/activeProductFormValue')
    private productFormValue!: (pId: string, productFormId: number) => UpdateProductFormEntry | null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private activeProductForm: ProductForm | null = null;
    private activeItemRows: ProductRowItem[] = [];
    private initialProductFormValues: ActiveProductFormValueObject | null = null;
    private defaultUpdateFunctions: UpdateFunctionEntry[] | null = null;
    private defaultEditableFields: ChecklistField[] = [];
    private isAddingNewRow = false;
    private availableSegments: RadioButtonGroupOptions[] = [];
    private debounceSetIsAddingRowToFalse = debounce(() => {
        this.setIsAddingRowToFalse();
    }, 100);
    private notifications: PopupNotificationOptions[][] = [];
    private flatNotifications: PopupNotificationOptions[] = [];
    private formFieldErrorsExist = false;
    private customFieldsWatcher: Function | null = null;

    private get isUpdatingData() {
        this.activeItemRows.some((activeItemRow) => activeItemRow.isUpdatingData);
        return this.activeItemRows.some((activeItemRow) => activeItemRow.isUpdatingData) || this.isAddingNewRow;
    }

    private get isDimensionsWizard() {
        return this.popupData.alreadyInStore && this.popupData.alreadyInStore !== null;
    }

    private capitalize(word: string) {
        return capitalizeWord(word);
    }

    private addNotification(title: string, description: string, errorType: 'error' | 'warning', index = 0) {
        if (this.notifications.length === 0) {
            this.notifications.push([]);
            // return;
        }
        this.notifications[index].push({
            title: this.$t(title),
            description: this.$t(description),
            errorType,
            index: index + 1,
        });
        this.flatNotifications = this.notifications.flat();
    }

    private getProductSlotRowReferences() {
        const segmentedMultipositionReference = this.$refs.segmentedMultiposition as SegmentedMultiposition;

        if (segmentedMultipositionReference && segmentedMultipositionReference.$refs.productSlotRow) {
            return segmentedMultipositionReference.$refs.productSlotRow;
        }

        return null;
    }

    private async getCurrentProductForm(): Promise<ProductForm | null> {
        const productFormId = this.popupData.productFormId;

        try {
            await this.getSingleProductForm(productFormId);
        } catch (e) {
            this.loadingOverlay.stop();
            this.addNotification(this.$t('Error occured'), (e as Error).message as string, 'error');

            this.$router.push({ name: RouteNames.error });
            return null;
        }

        return this.productForm(productFormId);
    }

    private async addNewRow(n: number, existingProductValues?: ChecklistFieldEntry[]) {
        this.isAddingNewRow = true;
        this.notifications.push([]);
        let newItemRows: ProductRowItem[];
        console.log('starting adding item rows...');
        try {
            newItemRows = await ConfiguratorService.addNewRow(
                this.initialProductFormValues,
                this.defaultUpdateFunctions,
                this.defaultEditableFields,
                this.availableSegments,
                this.addActiveProductForm,
                undefined,
                true,
                n,
                this.popupData.productFormId,
                existingProductValues ? existingProductValues : undefined,
                null,
                this.activeProductForm ? this.activeProductForm.isCustom : false
            );
        } catch (e) {
            this.addNotification(this.$t('Error occured'), (e as Error).message as string, 'error');
            this.isAddingNewRow = false;
            return Promise.reject(e);
        }
        setTimeout(() => {
            this.activeItemRows.push(...newItemRows);
            // const safeStringify = (obj: any) => {
            //     const seen = new WeakSet();
            //     return JSON.stringify(
            //         obj,
            //         (key, value) => {
            //             if (typeof value === 'object' && value !== null) {
            //                 if (seen.has(value)) return '[Circular]';
            //                 seen.add(value);
            //             }
            //             return value;
            //         },
            //         2
            //     );
            // };

            // const downloadJSON = (data: any, filename = 'data.json') => {
            //     const json = safeStringify(data);
            //     const blob = new Blob([json], { type: 'application/json' });
            //     const a = document.createElement('a');
            //     a.href = URL.createObjectURL(blob);
            //     a.download = filename;
            //     document.body.appendChild(a);
            //     a.click();
            //     document.body.removeChild(a);
            // };

            // // Call the function
            // downloadJSON(this.activeItemRows);
            // console.log(this.activeItemRows);
            // console.log('items added...');

            if (typeof n !== 'number' || n === 1) {
                this.verifyAndFixNewRowPositioning(newItemRows[0].activeProductFormId);
            }
            this.debounceSetIsAddingRowToFalse();
        }, 0);
        return Promise.resolve();
    }

    private async verifyAndFixNewRowPositioning(activeProductFormId: number) {
        await this.$nextTick();
        const rowElement = document.getElementById(String(activeProductFormId)) as any;

        cssMultipositionRowElementPositioning(rowElement);
    }

    private setIsAddingRowToFalse() {
        this.isAddingNewRow = false;
    }

    private updateItemRowsIsUpdatingState(activeProductFormId: number, newState: boolean) {
        const itemRowIndex = this.findRowIndexByProductFormIdInActiveItemRows(activeProductFormId);
        this.activeItemRows[itemRowIndex].isUpdatingData = newState;
    }

    private findRowIndexByProductFormIdInActiveItemRows(activeProductFormId: number) {
        return this.activeItemRows.findIndex((itemRow: ProductRowItem) => {
            return itemRow.activeProductFormId === activeProductFormId;
        });
    }

    private async deleteExistingRow({ activeProductFormId }: MultipositionDeleteMap) {
        if (this.activeItemRows.length === 1) {
            this.addNotification(this.$t('Error occured'), this.$t('Not possible to delete last row'), 'error');
            return;
        }

        const rowIndexToBeDeleted = this.activeItemRows.findIndex((itemRow: ProductRowItem) => {
            return itemRow.activeProductFormId === activeProductFormId;
        });

        if (rowIndexToBeDeleted === -1) {
            return;
        }
        this.activeItemRows.splice(rowIndexToBeDeleted, 1);
        this.notifications.splice(rowIndexToBeDeleted, 1);
        this.flatNotifications = this.notifications.flat();
    }

    private async setDefaultProductFormSettings() {
        if (this.activeProductForm == null) {
            return Promise.reject(new Error('Greška pri dohvaćanju proizvoda'));
        }
        this.initialProductFormValues = await extractDefaultValues({
            productForm: this.activeProductForm,
            productId: this.popupData.productId,
            clientId: this.popupData.clientId,
            productSystemName: this.popupData.productSystemName,
        });
        this.defaultUpdateFunctions = extractUpdateFunctions(this.activeProductForm);
        this.defaultEditableFields = ConfiguratorService.extractEditableFields(this.activeProductForm);
        this.availableSegments = ConfiguratorService.getSegmentNames(this.activeProductForm);
        return Promise.resolve();
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup, true);
    }

    private onShowNotification(event: {
        index: number;
        title: string;
        description: string;
        type: 'error' | 'warning';
    }) {
        this.addNotification(event.title, event.description, event.type, event.index);
    }
    private onClearNotifiactions(index: number) {
        this.notifications[index] = [];
        this.flatNotifications = this.notifications.flat();
    }

    private async validateFormFields() {
        this.formFieldErrorsExist = false;
        // Get visible fields and validate their values
        const formValues: FormValidationPayload[] = [];

        for (const itemRow of this.activeItemRows) {
            const rowValues = this.getProductFormValues(itemRow.activeProductFormId);

            formValues.push({
                rowKey: itemRow.activeProductFormId,
                values: { ...rowValues },
                fieldSegments: itemRow.editableFields as any,
            });
        }

        const errorState = checkForErrorsInFieldsAndReturnState(formValues);

        const hasErrors = errorState.hasErrors;
        this.formFieldErrorsExist = hasErrors;

        if (hasErrors) {
            this.showErrorState(errorState.errorDescription);
        }

        EventBus.$emit(EventBusEvents.formValidationFinished);
    }

    private showErrorState(description: string) {
        this.$notification.error({
            message: this.$t('Invalid characters detected in form fields:') as string,
            description: description as string,
        });
    }

    private setCustomFieldValues() {
        const rowData = this.popupData.customDimensions;

        this.activeItemRows.forEach(async (itemRow: any, i: number) => {
            const fields = this.isDimensionsWizard ? rowData : rowData[i].fields;

            fields.forEach(async (field: any) => {
                let timeout = 0;

                if (field.pId === 'p740593') {
                    timeout = 100;
                }

                setTimeout(() => {
                    this.updateActiveProductFormValue({
                        pId: field.pId,
                        value: field.value,
                        productFormId: itemRow.activeProductFormId,
                    });
                }, timeout);
            });
        });
    }

    private checkIfThereAreErrorsInHeightCalculation(selectedOmaricaValues: any[]) {
        return selectedOmaricaValues.some((value: any) => {
            return value === 'ERR' || value === '#' || value === '';
        });
    }

    private get selectedOmaricaSizes() {
        return this.activeItemRows.map((itemRow) => {
            const dimensionValue = this.productFormValue('p111592', itemRow.activeProductFormId);

            return dimensionValue;
        });
    }

    private setupCustomFieldsWatcher() {
        this.customFieldsWatcher = this.$watch('selectedOmaricaSizes', this.handleCustomFieldsChange);
    }

    private unwatchCustomFields() {
        if (this.customFieldsWatcher) {
            this.customFieldsWatcher();
            this.customFieldsWatcher = null; // Reset the watcher reference
        }
    }

    private handleCustomFieldsChange(newVal: any) {
        const errorsInHeightCalculation = this.checkIfThereAreErrorsInHeightCalculation(newVal);

        if (errorsInHeightCalculation) {
            return;
        }

        this.$nextTick(() => {
            this.setCustomFieldValues();
            this.unwatchCustomFields();
        });
    }

    private async created() {
        resetHelperState();
        this.loadingOverlay.start();
        this.activeProductForm = await this.getCurrentProductForm();

        try {
            await this.setDefaultProductFormSettings();
        } catch (e) {
            this.addNotification(this.$t('Error occured'), (e as Error).message as string, 'error');

            this.loadingOverlay.stop();
            return;
        }
        try {
            if (this.isDimensionsWizard) {
                // If this is provided, call has been made from dimensions wizard
                // and data should be present in the store.
                // Get the data, parse it to ChecklistFieldEntry format and set it.
                const activeProductForm = this.getProductFormValues(parseInt(this.popupData.alreadyInStore, 10));
                if (activeProductForm) {
                    const keys = Object.keys(activeProductForm);
                    const existingProductValues = keys.map((key: string) => {
                        return {
                            id: key,
                            value: activeProductForm[key],
                        };
                    }) as ChecklistFieldEntry[];
                    await this.addNewRow(this.popupData.timesToMultiply, existingProductValues);
                }
            } else {
                await this.addNewRow(this.popupData.timesToMultiply);
            }
        } catch (e) {
            this.addNotification(this.$t('Error occured'), (e as Error).message as string, 'error');

            this.loadingOverlay.stop();
            return;
        }
        this.loadingOverlay.stop();
        console.log('after everything...');

        EventBus.$on(EventBusEvents.showNotification, this.onShowNotification);
        EventBus.$on(EventBusEvents.clearNotifications, this.onClearNotifiactions);
    }

    private mounted() {
        if (this.popupData.customDimensions) {
            this.setupCustomFieldsWatcher();
        }
    }

    private beforeDestroy() {
        this.notifications = [];

        this.resetActiveProductForms();
        this.$notification.destroy();
        EventBus.$off(EventBusEvents.showNotification, this.onShowNotification);
        EventBus.$off(EventBusEvents.clearNotifications, this.onClearNotifiactions);
    }
}
