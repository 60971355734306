
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    name: 'ColorModuleWelcomeModal',
})
export default class ColorModuleWelcomeModal extends Vue {
    @Prop({ default: false }) private readonly infoModalVisible!: boolean;

    private closeModal() {
        this.$emit('closeModal');
    }
}
