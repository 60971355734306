import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { ConfigurationOverride } from '@/interfaces/general/ConfigurationOverride';
import { PaymentDetailsOptions } from '@/interfaces/components/projectNew/PaymentDetailsOptions';

export default class OfferAPI {
    public static async createNew(projectId: string, paymentTypeId: string | null) {
        let newOffer;

        const payload = {
            data: {
                type: 'offers',
                attributes: {},
                relationships: {
                    project: {
                        data: {
                            type: 'projects',
                            id: projectId,
                        },
                    },
                },
            },
        };

        if (paymentTypeId) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: paymentTypeId,
                },
            };
        }

        try {
            newOffer = (await ApiController.post(ApiRoutes.offer.create, payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newOffer);
    }

    public static async updateExisting(offerId: string, billingAddress: string | null, deliveryAddress: string | null) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {},
                relationships: {},
            },
        };

        if (deliveryAddress) {
            // @ts-ignore
            payload.data.relationships.deliveryAddress = {
                data: {
                    type: 'addresses',
                    id: deliveryAddress,
                },
            };
        } else {
            // @ts-ignore
            payload.data.relationships.deliveryAddress = {
                data: null,
            };
        }

        if (billingAddress) {
            // @ts-ignore
            payload.data.relationships.billingAddress = {
                data: {
                    type: 'addresses',
                    id: billingAddress,
                },
            };
        } else {
            // @ts-ignore
            payload.data.relationships.billingAddress = {
                data: null,
            };
        }

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateTaxAndExpirationDateAndConfigurationOverride(
        offerId: string,
        tax: number | null,
        expirationDate: string | null,
        comment: string,
        configurationOverride: ConfigurationOverride | undefined,
        paymentType: string | undefined | null
    ) {
        let offer;
        // todo - temporary default expiration date
        const newExpirationDate = moment().add(15, 'days').toISOString();

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    tax: tax !== null ? Number(tax) : undefined,
                    configurationOverride: configurationOverride != null ? configurationOverride : undefined,
                    expirationDate: moment.utc(expirationDate != null ? expirationDate : newExpirationDate),
                    comment,
                },
                relationships: {},
            },
        };

        if (paymentType) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: paymentType,
                },
            };
        }

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updatePaymentDetails(offerId: string, paymentDetailsOptions: PaymentDetailsOptions) {
        let offer;
        const newExpirationDate = moment().add(15, 'days').toISOString();

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    tax: paymentDetailsOptions.taxRate !== null ? paymentDetailsOptions.taxRate : undefined,
                    expirationDate: moment.utc(
                        paymentDetailsOptions.expirationDate != null
                            ? paymentDetailsOptions.expirationDate
                            : newExpirationDate
                    ),
                },
                relationships: {},
            },
        };

        if (paymentDetailsOptions.paymentType) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: paymentDetailsOptions.paymentType,
                },
            };
        }

        try {
            offer = await ApiController.patch(ApiRoutes.offer.update(offerId), payload);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateCommentAndConfigurationOverride(
        offerId: string,
        comment: string,
        configurationOverride: ConfigurationOverride | undefined
    ) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    configurationOverride: configurationOverride != null ? configurationOverride : undefined,
                    comment,
                },
                relationships: {},
            },
        };

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateConfigurationOverrideCommentAndExpirationDate(
        offerId: string,
        comment: string,
        expirationDate: string | null,
        configurationOverride: ConfigurationOverride | undefined
    ) {
        let offer;
        // todo - temporary default expiration date
        const newExpirationDate = moment().add(15, 'days').toISOString();

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    configurationOverride: configurationOverride != null ? configurationOverride : undefined,
                    comment,
                    expirationDate: moment.utc(expirationDate || newExpirationDate),
                },
                relationships: {},
            },
        };

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateOrderNotes(offerId: string, orderNotes: string | null) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    orderNotes: orderNotes !== '' ? orderNotes : null,
                },
            },
        };

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateCustomOrderNote(offerId: string, noteId: string) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {},
                relationships: {},
            },
        };

        if (noteId) {
            // @ts-ignore
            payload.data.relationships.note = {
                data: {
                    type: 'offer_notes',
                    id: noteId,
                },
            };
        }

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateDeliveryDate(offerId: string, date: string) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    requestedDeliveryDate: date,
                },
                relationships: {},
            },
        };

        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async updateSentToClient(offerId: string, state: boolean) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    sentToClient: state,
                },
            },
        };

        try {
            offer = await ApiController.patch(ApiRoutes.offer.update(offerId), payload);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async getById(id: string) {
        return (await ApiController.get(ApiRoutes.offer.getSingle(id))) as AxiosResponse;
    }

    public static async getForOverviewTab(id: string) {
        return await ApiController.get(ApiRoutes.offer.getForOverviewTab(id));
    }

    public static async getForOffersTab(id: string) {
        return await ApiController.get(ApiRoutes.offer.getForOffersTab(id));
    }

    public static async getOffersByPaymentType(paymentTypeId: string) {
        return (await ApiController.get(ApiRoutes.offer.getByPaymentType(paymentTypeId))) as AxiosResponse;
    }

    public static async copyExisting(offerId: string) {
        let newOffer;

        const payload = {
            data: {
                type: 'offers',
                attributes: {},
                relationships: {},
            },
        };

        try {
            newOffer = (await ApiController.post(ApiRoutes.offer.copy(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newOffer);
    }

    public static async copyExistingToNewOffer(offerId: string) {
        let newProject;

        try {
            newProject = (await ApiController.post(ApiRoutes.offer.copyToNewProject(offerId), {})) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(newProject);
    }

    public static async order(offerId: string) {
        let order;

        const payload = {
            data: {
                type: 'offers',
                attributes: {},
                relationships: {},
            },
        };

        try {
            order = (await ApiController.post(ApiRoutes.offer.order(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(order);
    }

    public static async orderByTechnologist(offerId: string) {
        let order;

        const payload = {
            data: {
                type: 'offers',
                attributes: {},
                relationships: {},
            },
        };

        try {
            order = (await ApiController.post(ApiRoutes.offer.orderByTechnologist(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(order);
    }

    public static async orderAccountingOffer(offerId: string) {
        let order;

        const payload = {
            data: {
                type: 'offers',
                attributes: {},
                relationships: {},
            },
        };

        try {
            order = (await ApiController.post(ApiRoutes.offer.orderAccountingOffer(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(order);
    }

    public static async clearMontage(offerId: string) {
        let offer;
        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {},
                relationships: {
                    montage: {
                        data: null,
                    },
                    deliveryAddress: {
                        data: null,
                    },
                    billingAddress: {
                        data: null,
                    },
                },
            },
        };

        try {
            offer = await ApiController.patch(ApiRoutes.offer.clearMontage(offerId), payload);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async getPdf(resourcePath: string) {
        return (await ApiController.get(resourcePath, null, true)) as AxiosResponse;
    }

    public static async updatePaymentType(offerId: string, paymentType: string | null) {
        let offer;

        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {},
                relationships: {},
            },
        };

        if (paymentType) {
            // @ts-ignore
            payload.data.relationships.paymentType = {
                data: {
                    type: 'payment_types',
                    id: paymentType,
                },
            };
        }
        try {
            offer = (await ApiController.patch(ApiRoutes.offer.update(offerId), payload)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(offer);
    }

    public static async requestAssistance(offerId: string) {
        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    assistanceRequired: true,
                },
                relationships: {},
            },
        };

        try {
            await ApiController.patch(ApiRoutes.offer.update(offerId), payload);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async cancelAssistance(offerId: string) {
        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {
                    assistanceRequired: false,
                },
                relationships: {},
            },
        };

        try {
            await ApiController.patch(ApiRoutes.offer.update(offerId), payload);
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async finishAssistance(offerId: string) {
        try {
            await ApiController.post(ApiRoutes.offer.finishAssistance(offerId), {});
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async deleteDeliveryAddress(offerId: string) {
        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {},
                relationships: {
                    deliveryAddress: {
                        data: null,
                    },
                },
            },
        };

        return await ApiController.patch(ApiRoutes.offer.updateAddress(offerId), payload);
    }

    public static async deleteBillingAddress(offerId: string) {
        const payload = {
            data: {
                type: 'offers',
                id: offerId,
                attributes: {},
                relationships: {
                    billingAddress: {
                        data: null,
                    },
                },
            },
        };

        return await ApiController.patch(ApiRoutes.offer.updateAddress(offerId), payload);
    }

    public static async send(offerId: string) {
        try {
            await ApiController.post(ApiRoutes.offer.send(offerId), {});
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async delete(offerId: string) {
        try {
            await ApiController.delete(ApiRoutes.offer.delete(offerId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async unlockOffer(offerId: string) {
        try {
            // @ts-ignore
            await ApiController.post(ApiRoutes.offer.unlockOffer(offerId));
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
