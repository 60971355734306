import { Model } from '@vuex-orm/core';
import SellingPriceManipulationAPI from '@/api/SellingPriceManipulationAPI';
import PriceManipulationType from '@/models/PriceManipulationType';
import ProductCategory from '@/models/ProductCategory';
import Offer from '@/models/Offer';

export default class SellingPriceManipulation extends Model {
    public static entity = 'sellingPriceManipulation';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
            value: this.number(0),
            priceManipulationType: this.belongsTo(PriceManipulationType, 'priceManipulationType_id'),
            priceManipulationType_id: this.attr(null),
            productCategory_id: this.attr(null),
            productCategory: this.belongsTo(ProductCategory, 'productCategory_id'),
            offer: this.belongsTo(Offer, 'offer_id'),
            offer_id: this.attr(null),
        };
    }

    public static async getAll() {
        let priceManipulations;
        try {
            priceManipulations = await SellingPriceManipulationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulations.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve();
    }

    public static async getById(priceManipulationId: string) {
        let priceManipulation;
        try {
            priceManipulation = await SellingPriceManipulationAPI.getById(priceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve(priceManipulation);
    }

    public static async updateExisting(priceManipulationId: string, priceManipulationInfo: any) {
        let priceManipulation;
        try {
            priceManipulation = await SellingPriceManipulationAPI.update(priceManipulationId, priceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve(priceManipulation);
    }

    public static async createNew(priceManipulationInfo: any) {
        let priceManipulation;
        try {
            priceManipulation = await SellingPriceManipulationAPI.create(priceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: priceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve(priceManipulation);
    }

    public static async deleteExisting(priceManipulationId: string) {
        try {
            await SellingPriceManipulationAPI.delete(priceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        await SellingPriceManipulation.delete(priceManipulationId);
        return Promise.resolve();
    }
    public id!: string;
    public name!: string | null;
    public value!: number;
    public priceManipulationType!: PriceManipulationType;
    public productCategory!: ProductCategory;
    public offer!: Offer;
    // tslint:disable-next-line:variable-name
    public offer_id!: string;
}
