import { ChecklistFieldImage } from '@/interfaces/components/configurator/ChecklistFieldImage';
import { ChecklistBoolField } from '@/interfaces/components/configurator/ChecklistBoolField';
import { ChecklistDropdownField } from '@/interfaces/components/configurator/ChecklistDropdownField';
import { ChecklistFloatField } from '@/interfaces/components/configurator/ChecklistFloatField';
import { ChecklistIntegerField } from '@/interfaces/components/configurator/ChecklistIntegerField';
import { ChecklistStringField } from '@/interfaces/components/configurator/ChecklistStringField';
import { ChecklistUpdateFunction } from '@/interfaces/components/configurator/ChecklistUpdateFunction';
import { ChecklistVisibleFunction } from '@/interfaces/components/configurator/ChecklistVisibleFunction';
import { Model } from '@vuex-orm/core';
import ChecklistFieldAPI from '@/api/ChecklistFieldAPI';
import Image from '@/models/Image';
import { AvailableChecklistFields } from '@/interfaces/components/cms/checklistFields/AvailableChecklistFields';
import ChecklistSegment from '@/models/ChecklistSegment';

export default class ChecklistField extends Model {
    public static entity = 'checklistField';

    public static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            position: this.number(null),
            multiposition: this.boolean(null),
            pId: this.string(''),
            isVisiblePdf: this.boolean(true),
            level: this.number(null),
            fieldType: this.string(''),
            createdAt: this.string(''),
            updatedAt: this.string(''),
            description: this.attr(null),
            image_id: this.string(''),
            image: this.belongsTo(Image, 'image_id'),
            checklistSegment_id: this.string(''),
            checklistSegment: this.belongsTo(ChecklistSegment, 'checklistSegment_id'),
            validWarranty: this.boolean(false),
        };
    }

    public static async getAll() {
        let checklistFields;

        try {
            checklistFields = await ChecklistFieldAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: checklistFields.data,
            insertOrUpdate: ['checklistSegment', 'checklist', 'productForms'],
        });
        return Promise.resolve(checklistFields);
    }

    public static async getById(checklistFieldId: string) {
        let checklistField;

        try {
            checklistField = await ChecklistFieldAPI.getById(checklistFieldId);
        } catch (e) {
            return Promise.reject(e);
        }

        this.insertOrUpdate({
            data: checklistField.data,
        });
        return Promise.resolve(checklistField);
    }

    public static async updateExisting(checklistFieldId: string, checklistFieldInfo: AvailableChecklistFields) {
        let checklistField;

        try {
            checklistField = await ChecklistFieldAPI.update(checklistFieldId, checklistFieldInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await ChecklistField.delete(checklistFieldId);
        this.insertOrUpdate({
            data: checklistField.data,
            insertOrUpdate: ['image'],
        });
        return Promise.resolve();
    }

    public id!: string;
    public name!: string;
    public position!: number;
    public multiposition!: boolean;
    public pId!: string;
    public level!: number;
    public fieldType!: string;
    public createdAt!: string;
    public updatedAt!: string;
    public description!: string | null;
    public isVisiblePdf!: boolean;
    public image!: null | ChecklistFieldImage;
    public checklistBoolField!: null | ChecklistBoolField;
    public checklistDropdownField!: null | ChecklistDropdownField;
    public checklistFloatField!: null | ChecklistFloatField;
    public checklistIntegerField!: null | ChecklistIntegerField;
    public checklistStringField!: null | ChecklistStringField;
    public checklistUpdateFunctions!: ChecklistUpdateFunction[];
    public checklistVisibleFunction!: ChecklistVisibleFunction;
}
