import { Model } from '@vuex-orm/core';
import TransferredOrderAPI from '@/api/TransferredOrderAPI';
import Client from '@/models/Client';
import Offer from '@/models/Offer';

export default class TransferredOrder extends Model {
    public static entity = 'transferredOrder';

    public static fields() {
        return {
            id: this.attr(null),
            type: this.string(''),
            data: this.attr({}),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
            client: this.belongsTo(Client, 'client_id'),
            offer: this.belongsTo(Offer, 'offer_id'),
        };
    }

    public static async getAll() {
        let allTransferredOrders;

        try {
            allTransferredOrders = await TransferredOrderAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }

        this.create({ data: allTransferredOrders.data });

        return Promise.resolve();
    }

    public id!: string;
    public type!: string;
    public createdAt!: string;
    public updatedAt!: string;
    public client!: Client;
    public offer!: Offer;
}
