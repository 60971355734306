
import { Component, Vue, Prop } from 'vue-property-decorator';
import ColorModuleSearch from '@/components/global/color-module/ColorModuleSearch.vue';

@Component({
    name: 'ColorModuleRequest',
    components: {
        ColorModuleSearch,
    },
})
export default class ColorModuleRequest extends Vue {
    @Prop({ required: true }) private value!: any;
    @Prop({ required: true }) private validationErrors!: any;
}
