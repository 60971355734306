import { MutationTree } from 'vuex';
import ILocalState from './stateInterface';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { getOnlyAbortErrors } from '@/helpers/project/ProductFormVersionHelper';
import { OfferItemsErrors } from '@/interfaces/components/projectNew/OfferItemsErrors';
import { defaultValueWarnings } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import Vue from 'vue';

const mutations: MutationTree<ILocalState> = {
    addProductForm(state, payload) {
        const searchedProductIndex = state.productForms.findIndex((product: ProductForm) => {
            return product.id === payload.id;
        });

        if (searchedProductIndex === -1) {
            state.productForms.push(payload);
            return;
        }

        state.productForms[searchedProductIndex] = payload;
    },
    addActiveProductForm(state, payload) {
        state.activeProductForms.push(payload);
    },
    resetActiveProductForms(state) {
        state.activeProductForms = [];
    },
    updateActiveProductFormValue(state, payload) {
        const productForm = state.activeProductForms[payload.position].activeProductForm;
        productForm[payload.id] = payload.value;
    },
    addInitialOfferItems(state, payload) {
        state.offerRecalculationErrorItems = payload;
    },
    updateOfferItem(state, payload) {
        const offerItemIndex = state.offerRecalculationErrorItems.findIndex(
            (offerItemRecalculationError) => offerItemRecalculationError.offerItemId === payload.offerItemId
        );

        if (offerItemIndex === -1) {
            return;
        }

        const payloadContainsErrors =
            getOnlyAbortErrors(payload.frontendErrors).length > 0 || payload.backendErrors.length > 0;
        const newOfferItem = {
            ...state.offerRecalculationErrorItems[offerItemIndex],
            status: payloadContainsErrors ? 'error' : 'success',
            frontendErrors:
                payload.frontendErrors.length > 0
                    ? payload.frontendErrors
                    : state.offerRecalculationErrorItems[offerItemIndex].frontendErrors,
            backendErrors:
                payload.backendErrors.length > 0
                    ? payload.backendErrors
                    : state.offerRecalculationErrorItems[offerItemIndex].backendErrors,
            newPrice: payload.newPrice,
            activeProductFormId: payload.activeProductFormId,
            type: payload.type,
            productFormId: payload.productFormId,
        };

        state.offerRecalculationErrorItems = [
            ...state.offerRecalculationErrorItems.filter((offerItem) => offerItem.offerItemId !== payload.offerItemId),
            newOfferItem,
        ];
    },
    updateRowNumbers(state, rowNumbersAndOfferItemIds) {
        for (let i = 0; i < rowNumbersAndOfferItemIds.length; i++) {
            const offerItemId = rowNumbersAndOfferItemIds[i].id;
            const offerItem = state.offerRecalculationErrorItems.find(
                (offerItem) => offerItem.offerItemId === offerItemId
            );
            if (offerItem) {
                offerItem.rowNumber = i + 1;
            }
        }
    },
    clearOfferItems(state) {
        state.offerRecalculationErrorItems = [];
    },
    setCallbackAfterCalculation(state, callback) {
        state.callbackAfterCalculation = callback;
    },
    clearCallbackAfterCalculation(state) {
        state.callbackAfterCalculation = null;
    },
    setRecalculationAndCallbackLabel(state, label) {
        state.recalculationAndCallbackLabel = label;
    },
    clearRecalculationAndCallbackLabel(state) {
        state.recalculationAndCallbackLabel = '';
    },
    updateOfferErrorState(state, payload: OfferItemsErrors) {
        state.offerErrorState = { ...payload };
    },
    setDefaultValueWarnings(state, payload: defaultValueWarnings[]) {
        state.defaultValueWarnings = [...payload];
    },
    setCustomValuesOverride(state, payload: any[]) {
        state.customValuesOverride = [...payload];
    },
    updateRecalculationErrorsWithDefaultValueWarnings(state) {
        for (const errorItem of state.offerRecalculationErrorItems) {
            const currentItemWarnings = state.defaultValueWarnings.find(
                (warning: defaultValueWarnings) => warning.id === errorItem.rowNumber
            );
            if (!currentItemWarnings?.warnings.length) {
                continue;
            }

            Vue.set(errorItem, 'defaultValueWarnings', currentItemWarnings);
        }
    },
    updateOfferPriceValidState(state, payload: boolean) {
        state.offerPricesHaveErrors = payload;
    },
    setImportingStatus(state, payload: boolean) {
        state.isImportingColors = payload;
    },
};

export default mutations;
