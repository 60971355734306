
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChecklistFieldModule from '@/components/views/productView/ChecklistFieldModule.vue';
import MultipositionHeader from '@/components/global/popup/MultipositionHeader.vue';
import ProductCombination from '@/components/views/productView/ProductCombination.vue';
import CombinationsSelectorPopup from '@/components/global/popup/CombinationsSelectorPopup.vue';
import CombinationsEditPopup from '@/components/global/popup/CombinationsEditPopup.vue';
import ProductSlotRow from '@/components/global/popup/ProductSlotRow.vue';
import { MultipositionDeleteMap } from '@/interfaces/general/MultipositionDeleteMap';
import { ProductRowItem } from '@/interfaces/general/ProductRowItem';
import { ProductCombinations } from '@/interfaces/components/projectNew/ProductCombinations';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import { debounce } from 'vue-debounce';
import ConfiguratorService from '@/services/ConfiguratorService';
import { Action } from 'vuex-class';
import { UpdateProductFormEntry } from '@/interfaces/components/configurator/UpdateProductFormEntry';
import {
    createNewRowsWithPastedData,
    determineIfSingleValueIsPasted,
    extractCellsAsArraysFromString,
    updateExistingRowsWithPastedData,
} from '@/helpers/FormDataHelper';
import { ChecklistFieldEntry } from '@/interfaces/components/configurator/ChecklistFieldEntry';
import { Tabs } from 'ant-design-vue';
import OfferRepository from '@/repositories/OfferRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { setRowColor } from '@/helpers/FormDataHelper';
import { CustomColors } from '@/enums/global/CustomColors';

@Component({
    name: 'SegmentedMultiposition',
    components: {
        ChecklistFieldModule,
        MultipositionHeader,
        ProductSlotRow,
        Tabs,
        TabPane: Tabs.TabPane,
        ProductCombination,
        CombinationsSelectorPopup,
        CombinationsEditPopup,
    },
})
export default class SegmentedMultiposition extends Vue {
    @Action('configurator/updateActiveProductFormValue')
    private updateActiveProductFormValue!: ({ pId, value, productFormId }: UpdateProductFormEntry) => void;

    @Prop({ required: true }) private deleteExistingRow!: (multipositionDeleteMap: MultipositionDeleteMap) => void;
    @Prop({ default: null }) private addNewRow!: (
        n: number,
        existingProductValues: ChecklistFieldEntry[]
    ) => void | null;
    @Prop({ default: null }) private addNewRowInEditMode!: (
        firstActiveItemRow: ProductRowItem,
        existingProductValues: ChecklistFieldEntry[]
    ) => void | null;
    @Prop({ default: null }) private updateItemRowsIsUpdatingState!: (itemRowIndex: number, newState: boolean) => void;
    @Prop({ required: true }) private isUpdatingData!: boolean;
    @Prop({ default: false }) private isAddingProducts!: boolean;
    @Prop({ default: false }) private isCustomProduct!: boolean;
    @Prop({ required: true }) private activeItemRows!: ProductRowItem[];
    @Prop({ default: 1 }) private numberOfProductForms!: number;
    @Prop({ required: true }) private offerId!: string;
    @Prop({ required: true }) private availableSegments!: RadioButtonGroupOptions[];
    @Prop({ required: false, default: false }) private isWindowCombinationWizard!: boolean;

    private selectedSegment: string | null = null;
    private hiddenIndices: boolean[] = [];
    private level: number = 2;
    private selectedCombinationRows: ProductRowItem[] = [];
    private combinationsSelectorActive: boolean = false;
    private count: number = 0;
    private penultimateSelectedLevel = 0;

    private get userGroup() {
        return this.$store.getters['jwtData/currentUserGroup'];
    }

    private get showCombinations() {
        const currentSegment = this.availableSegments.find((seg) => seg.value === this.selectedSegment);
        return currentSegment?.isLocked;
    }

    private get productCombinations() {
        const combinations: ProductCombinations = {};

        for (const row of this.activeItemRows) {
            if (row.connection === null) {
                continue;
            }

            if (!combinations.hasOwnProperty(row.connection)) {
                this.$set(combinations, row.connection, []);
            }

            combinations[row.connection].push(row);
        }

        return combinations;
    }

    private get rowsWithNoConnection() {
        return this.activeItemRows.filter((row) => {
            return row.connection === null;
        });
    }

    private onRemoveCombinationConnection(rows: ProductRowItem[]) {
        rows.forEach((row: ProductRowItem) => {
            row.connection = null;
            row.color = '';
        });

        this.$notification.success({
            message: this.$t('Connection removed!') as string,
            description: '',
        });
    }

    private onEditCombination(rows: ProductRowItem[]) {
        this.selectedCombinationRows = rows;
        EventBus.$emit(EventBusEvents.openCombinationsEditPopup);
    }

    private openCombinationsSelector() {
        EventBus.$emit(EventBusEvents.openCombinationsSelectorPopup);
        this.combinationsSelectorActive = true;
    }

    private resetCombinationsSelectorStatus() {
        this.combinationsSelectorActive = false;
    }

    private get productCombinationPayload() {
        return {
            hiddenIndices: this.hiddenIndices,
            isLoading: this.isUpdatingData,
            offer: this.offer,
            isCustomProduct: this.isCustomProduct,
            level: this.level,
            deleteExistingRow: this.deleteExistingRow,
            isEditMode: false,
            isUpdating: true,
            selectedSegment: this.selectedSegment,
            updateItemRowsIsUpdatingState: this.updateItemRowsIsUpdatingState,
        };
    }

    private addNewCombination(productFormIds: number[]) {
        const colors: Record<string | number, any> = { ...CustomColors };

        productFormIds.forEach((id: number) => {
            const selectedRowItem = this.activeItemRows.find((rowItem: ProductRowItem) => {
                return rowItem.activeProductFormId === id;
            });

            if (selectedRowItem) {
                selectedRowItem.connection = productFormIds[0];
            }
        });

        if (this.isAddingProducts) {
            this.activeItemRows.forEach((productRowItem) => {
                setRowColor(productRowItem, colors);
            });
        }
    }

    private debouncedUpdateHiddenIndicesInMultiposition = debounce(() => {
        this.updateHiddenIndicesInMultiposition();
    }, 200);

    private debouncedUpdateHiddenIndices = debounce(() => {
        this.updateHiddenIndices();
    }, 200);

    private get allSegments() {
        return [
            {
                label: this.$t('Svi'),
                value: 'All',
            },
            ...this.availableSegments,
        ];
    }

    private get offer() {
        if (this.offerId == null) {
            return null;
        }

        return OfferRepository.getOfferById(this.offerId);
    }

    public switchLevel(level: number) {
        this.level = -1;
        this.$nextTick(() => {
            this.level = level;

            if (!this.showCombinations || !this.isWindowCombinationWizard) {
                this.penultimateSelectedLevel = level;
            }
        });
    }

    private handleConnection(e: number) {
        this.activeItemRows.forEach((row) => {
            row.connection = e;
        });
    }

    private handleProduction(e: boolean) {
        this.activeItemRows.forEach((row) => {
            row.production = e;
        });
    }

    private async updateHiddenIndices() {
        if (
            this.selectedSegment == null ||
            (!Object.keys(this.productCombinations).length && this.showCombinations && !this.combinationsSelectorActive)
        ) {
            return;
        }

        let productRowRefs: any[];

        // In case product combinations are used, we dig for refs inside child component
        if (this.showCombinations && !this.combinationsSelectorActive) {
            await this.$nextTick();
            productRowRefs = (this.$refs?.productCombination as ProductCombination[])
                ?.map((combinationRef: ProductCombination) => {
                    return combinationRef.$refs.productSlotRow as ProductSlotRow[];
                })
                .flat();
        } else if (this.showCombinations && this.combinationsSelectorActive) {
            await this.$nextTick();
            productRowRefs = (
                ((this.$refs?.combinationsSelector as any)?.$refs.productCombination as ProductCombination).$refs
                    .productSlotRow as ProductSlotRow[]
            ).flat();
        } else {
            productRowRefs = this.$refs.productSlotRow as ProductSlotRow[];
        }

        const allDisabledStates = ConfiguratorService.getAllDisabledStatesFromRow(productRowRefs);

        const firstRow = ConfiguratorService.getInitialColumnVisibilityBasedOnSelectedSegment(
            this.activeItemRows[0],
            this.selectedSegment
        );

        this.hiddenIndices = ConfiguratorService.determineColumnVisibility(firstRow, allDisabledStates);
    }

    private updateHiddenIndicesInMultiposition() {
        this.updateHiddenIndices();
        this.updateWidthOfProductSlotWrappers();
    }

    private updateWidthOfProductSlotWrappers() {
        // in case of combinations, its updated in a separate component
        if (this.showCombinations) {
            EventBus.$emit(EventBusEvents.updateWidthOfProductSlotWrappers);
            return;
        }
        // needs to be updated if there are significant width changes in the DOM
        // otherwise, the sticky columns won't work properly
        this.$nextTick(async () => {
            let wrapperElements;

            wrapperElements = [
                ...(this.$refs.productSlotRow as ProductSlotRow[]).map(
                    (productSlotRow) => productSlotRow.$refs.wrapper
                ),
                (this.$refs.multipositionHeader as MultipositionHeader).$refs.wrapper,
            ] as HTMLElement[];

            for await (const wrapperElement of wrapperElements) {
                wrapperElement.style.width = 'auto';
                await this.$nextTick();
                wrapperElement.style.width = `${wrapperElement.scrollWidth}px`;
            }
        });
    }

    private radioButtonChange(segment: string) {
        this.selectedSegment = segment;
    }

    private onPaste(evt: any) {
        if (evt.clipboardData == null || this.isUpdatingData || this.isCustomProduct) {
            return;
        }

        let cells = extractCellsAsArraysFromString(evt.clipboardData.getData('text'));

        if (determineIfSingleValueIsPasted(cells)) {
            evt.preventDefault();
        } else {
            return;
        }

        const productSlotRowElement = evt.target.closest('.c-add-product-row__wrapper');
        const productSlotRowNumber = productSlotRowElement ? productSlotRowElement.dataset.rowNumber : null;
        if (cells.length > 50) {
            cells = cells.slice(0, 50);
            this.$notification.warn({
                message: (this.$t('Započeto kopiranje __NUMBEROFITEMS__ pozicija') as string).replace(
                    '__NUMBEROFITEMS__',
                    String(cells.length)
                ),
                // tslint:disable-next-line:max-line-length
                description: this.$t(
                    'Limit zaljepljenih proizvoda je 50 stoga će se zaljepiti samo prvih 50 proizvoda'
                ) as string,
            });
        } else {
            this.$notification.info({
                message: (this.$t('Započeto kopiranje __NUMBEROFITEMS__ pozicija') as string).replace(
                    '__NUMBEROFITEMS__',
                    String(cells.length)
                ),
                description: this.$t('Ova akcija može potrajati neko vrijeme stoga molimo za strpljenje') as string,
            });
        }

        const widthElement = productSlotRowElement
            .querySelector('[data-test-id="p7402"]')
            ?.closest('.is-multiposition') as HTMLElement;

        const heightElement = productSlotRowElement
            .querySelector('[data-test-id="p7403"]')
            ?.closest('.is-multiposition') as HTMLElement;

        const isAlternativeFormForPasting =
            !widthElement.classList.contains('is-multiposition--visible') &&
            !heightElement.classList.contains('is-multiposition--visible');

        updateExistingRowsWithPastedData(this.activeItemRows, cells, productSlotRowNumber, isAlternativeFormForPasting);
        createNewRowsWithPastedData(
            cells,
            this.activeItemRows,
            this.addNewRow,
            this.addNewRowInEditMode,
            productSlotRowNumber,
            isAlternativeFormForPasting
        );
    }

    private setConfiguratorLevel() {
        const expertUserGroups = ['1', '2', '3'];

        if (expertUserGroups.includes(String(this.userGroup))) {
            this.level = 2;
        } else {
            this.level = 0;
        }

        this.penultimateSelectedLevel = this.level;
    }

    private async mounted() {
        this.$el.addEventListener('paste', this.onPaste);
        this.setConfiguratorLevel();
    }

    private beforeDestroy() {
        this.$el.removeEventListener('paste', this.onPaste);
    }

    @Watch('availableSegments', { deep: true, immediate: true })
    private onAvailableSegmentsChange() {
        this.selectedSegment = this.availableSegments[0]?.value as string;
    }

    @Watch('selectedSegment', { deep: true })
    private async onSelectedSegmentChange() {
        this.debouncedUpdateHiddenIndicesInMultiposition();
        this.$emit('isLockedSegment', this.showCombinations);
        console.log(this.showCombinations, this.isWindowCombinationWizard);
        if (this.showCombinations && this.isWindowCombinationWizard) {
            this.switchLevel(2);
        } else {
            console.log(this.penultimateSelectedLevel);
            this.switchLevel(this.penultimateSelectedLevel);
        }

        if (this.showCombinations && this.count === 0) {
            this.count++;
        }
    }
}
