import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class TransferredOrderAPI {
    public static async getAll() {
        let TransferredOrder;

        try {
            TransferredOrder = (await ApiController.get(ApiRoutes.offer.xmlOrders)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(TransferredOrder);
    }
}
