import { Model } from '@vuex-orm/core';
import PurchasePriceManipulationAPI from '@/api/PurchasePriceManipulationAPI';
import PriceManipulationType from '@/models/PriceManipulationType';
import ProductCategory from '@/models/ProductCategory';
import Offer from '@/models/Offer';

export default class PurchasePriceManipulation extends Model {
    public static entity = 'purchasePriceManipulation';
    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(null),
            value: this.number(0),
            priceManipulationType: this.belongsTo(PriceManipulationType, 'priceManipulationType_id'),
            priceManipulationType_id: this.attr(null),
            productCategory_id: this.attr(null),
            productCategory: this.belongsTo(ProductCategory, 'productCategory_id'),
            offer: this.belongsTo(Offer, 'offer_id'),
            offer_id: this.attr(null),
        };
    }

    public static async getAll() {
        let priceManipulations;
        try {
            priceManipulations = await PurchasePriceManipulationAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulations.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve();
    }

    public static async getById(priceManipulationId: string) {
        let priceManipulation;
        try {
            priceManipulation = await PurchasePriceManipulationAPI.getById(priceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: priceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve(priceManipulation);
    }

    public static async updateExisting(priceManipulationId: string, priceManipulationInfo: any) {
        let priceManipulation;
        try {
            priceManipulation = await PurchasePriceManipulationAPI.update(priceManipulationId, priceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }

        if (priceManipulation.data.productCategory === null) {
            priceManipulation.data.productCategory_id = null;
        }

        await this.insertOrUpdate({
            data: priceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });

        return Promise.resolve(priceManipulation);
    }

    public static async createNew(priceManipulationInfo: any) {
        let priceManipulation;
        try {
            priceManipulation = await PurchasePriceManipulationAPI.create(priceManipulationInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        await this.insertOrUpdate({
            data: priceManipulation.data,
            insertOrUpdate: ['priceManipulationType', 'productCategory'],
        });
        return Promise.resolve(priceManipulation);
    }

    public static async deleteExisting(priceManipulationId: string) {
        try {
            await PurchasePriceManipulationAPI.delete(priceManipulationId);
        } catch (e) {
            return Promise.reject(e);
        }
        await PurchasePriceManipulation.delete(priceManipulationId);
        return Promise.resolve();
    }

    public id!: string;
    public name!: string | null;
    public value!: number;
    // tslint:disable-next-line:variable-name
    public offer_id!: string;
    public priceManipulationType!: PriceManipulationType;
    public productCategory!: ProductCategory;
    public offer!: Offer;
}
