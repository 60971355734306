import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';
import { AvailableLabelFields } from '@/interfaces/components/cms/AvailableLabelFields';
import moment from 'moment';

export default class PaymentRecordAPI {
    public static async getAll() {
        let paymentRecords;

        try {
            paymentRecords = (await ApiController.get(ApiRoutes.paymentRecords.get)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentRecords);
    }

    public static async getById(paymentRecordId: string) {
        let paymentRecord;

        try {
            paymentRecord = (await ApiController.get(
                ApiRoutes.paymentRecords.getById(paymentRecordId)
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentRecord);
    }

    public static async delete(paymentRecordId: string) {
        try {
            await ApiController.delete(ApiRoutes.paymentRecords.delete(paymentRecordId));
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve();
    }

    public static async update(paymentRecordId: string, paymentRecordInfo: AvailableLabelFields) {
        let paymentRecord;
        const request = {
            data: {
                type: 'payment_records',
                id: paymentRecordId,
                attributes: {
                    amount: paymentRecordInfo.amount,
                    paymentDate: moment.utc(paymentRecordInfo.paymentDate),
                    percentagePaid: paymentRecordInfo?.percentagePaid,
                },
                relationships: {
                    currency: {
                        data: {
                            type: 'currencies',
                            id: paymentRecordInfo.currency,
                        },
                    },
                },
            },
        };

        try {
            paymentRecord = (await ApiController.patch(
                ApiRoutes.paymentRecords.update(paymentRecordId),
                request
            )) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentRecord);
    }

    public static async create(paymentRecordInfo: AvailableLabelFields, selectedOfferId: string) {
        let paymentRecord;

        const request = {
            data: {
                type: 'payment_records',
                attributes: {
                    amount: paymentRecordInfo.amount,
                    paymentDate: moment.utc(paymentRecordInfo.paymentDate),
                    percentagePaid: paymentRecordInfo?.percentagePaid,
                },
                relationships: {
                    project: {
                        data: {
                            type: 'projects',
                            id: paymentRecordInfo.projectId,
                        },
                    },
                    currency: {
                        data: {
                            type: 'currencies',
                            id: paymentRecordInfo.currency,
                        },
                    },
                    offer: {
                        data: {
                            type: 'offers',
                            id: selectedOfferId,
                        },
                    },
                },
            },
        };

        try {
            paymentRecord = (await ApiController.post(ApiRoutes.paymentRecords.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(paymentRecord);
    }
}
