import { GetterTree } from 'vuex';
import ILocalState from './stateInterface';
import { ProductForm } from '@/interfaces/components/configurator/ProductForm';
import { KeyedProductForm } from '@/interfaces/general/KeyedProductForm';

const getters: GetterTree<ILocalState, {}> = {
    productForms(state) {
        return state.productForms;
    },
    productForm: (state) => (productFormId: string) => {
        if (state.productForms.length <= 0) {
            return null;
        }

        const searchedProduct = state.productForms.find((product: ProductForm) => {
            return product.id === productFormId;
        });

        if (searchedProduct === undefined) {
            return null;
        }

        return searchedProduct;
    },
    activeProductFormValue: (state) => (pId: string, productFormId: number) => {
        const filteredProductForm: KeyedProductForm | undefined = state.activeProductForms.find(
            (activeProductForm: KeyedProductForm) => {
                return activeProductForm.id === productFormId;
            }
        );
        if (filteredProductForm == null) {
            return null;
        }
        return filteredProductForm.activeProductForm[pId];
    },
    activeProductForm: (state) => (productFormId: number) => {
        const filteredProductForm: KeyedProductForm | undefined = state.activeProductForms.find(
            (activeProductForm: KeyedProductForm) => {
                return activeProductForm.id === productFormId;
            }
        );
        if (filteredProductForm == null) {
            return null;
        }
        return filteredProductForm.activeProductForm;
    },
    activeProductForms(state) {
        return state.activeProductForms;
    },
    offerItemRecalculationErrors(state) {
        return state.offerRecalculationErrorItems.sort((a: any, b: any) => a.rowNumber - b.rowNumber);
    },
    callbackAfterCalculation(state) {
        return state.callbackAfterCalculation;
    },
    recalculationAndCallbackLabel(state) {
        return state.recalculationAndCallbackLabel;
    },
    offerErrorState(state) {
        return state.offerErrorState;
    },
    defaultValueWarnings(state) {
        return state.defaultValueWarnings;
    },
    customValuesOverride(state) {
        return state.customValuesOverride;
    },
    offerPricesHaveErrors(state) {
        return state.offerPricesHaveErrors;
    },
    isImportingColors(state) {
        return state.isImportingColors;
    },
};

export default getters;
