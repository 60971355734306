import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class ColorAPI {
    public static async getAll(query: string) {
        let colors;

        try {
            colors = (await ApiController.get(ApiRoutes.color.get(query))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(colors);
    }

    public static async getPowders() {
        let powders;

        try {
            powders = (await ApiController.get(ApiRoutes.color.getPowders)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(powders);
    }

    public static async getPowderPrices() {
        let prices;

        try {
            prices = (await ApiController.get(ApiRoutes.color.getPowderPrices)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(prices);
    }

    public static async getSurfaces() {
        let surfaces;

        try {
            surfaces = (await ApiController.get(ApiRoutes.color.getSurfaces)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(surfaces);
    }

    public static async getQualities() {
        let qualities;

        try {
            qualities = (await ApiController.get(ApiRoutes.color.getQualities)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(qualities);
    }

    public static async getGlosses() {
        let glosses;

        try {
            glosses = (await ApiController.get(ApiRoutes.color.getGlosses)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(glosses);
    }

    public static async getEffects() {
        let effects;

        try {
            effects = (await ApiController.get(ApiRoutes.color.getEffects)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(effects);
    }

    public static async getCharts() {
        let charts;

        try {
            charts = (await ApiController.get(ApiRoutes.color.getCharts)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(charts);
    }

    public static async getCollections() {
        let collections;

        try {
            collections = (await ApiController.get(ApiRoutes.color.getCollections)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(collections);
    }
}
