import { VueConstructor } from 'vue';

interface HtmlToPaperOptions {
    name?: string;
    specs?: string | string[];
    styles?: string[];
    windowTitle?: string;
}

function addStyles(win: Window | null, styles: string[]): Promise<void[]> {
    if (!win) return Promise.resolve([]);
    const head = win.document.getElementsByTagName('head')[0];
    if (!head) return Promise.resolve([]);

    return Promise.all(
        styles.map((style) => {
            return new Promise<void>((resolve, reject) => {
                const link = win.document.createElement('link');
                console.log(style);
                link.setAttribute('rel', 'stylesheet');
                link.setAttribute('type', 'text/css');
                link.setAttribute('href', style);
                link.onload = () => resolve();
                link.onerror = reject;
                head.appendChild(link);
            });
        })
    );
}

function openWindow(url: string, name: string, props: string): Window | null {
    const windowRef = window.open(url, name, props);
    if (windowRef && !windowRef.opener) {
        windowRef.opener = self;
    }
    windowRef?.focus();
    return windowRef;
}

const VueHtmlToPaper = {
    install(Vue: VueConstructor, options: HtmlToPaperOptions = {}) {
        Vue.prototype.$htmlToPaper = (el: string, localOptions: HtmlToPaperOptions = {}, cb: () => void = () => {}) => {
            const defaultName = '_blank';
            const defaultSpecs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
            const defaultStyles: string[] = [];
            const defaultWindowTitle = document.title;

            let {
                name = defaultName,
                specs = defaultSpecs,
                styles = defaultStyles,
                windowTitle = defaultWindowTitle,
            } = { ...options, ...localOptions };

            specs = Array.isArray(specs) ? specs.join(',') : specs;

            const element = document.getElementById(el);
            if (!element) {
                alert(`Element to print #${el} not found!`);
                return;
            }

            const win = openWindow('', name, specs);
            if (!win) {
                alert('Could not open print window. Please allow popups and try again.');
                return;
            }

            win.document.write(`
                <html>
                    <head>
                        <title>${windowTitle}</title>
                    </head>
                    <body>
                        ${element.innerHTML}
                    </body>
                </html>
            `);

            addStyles(win, styles)
                .then(() => {
                    win.document.close();
                    win.focus();

                    let isCallbackCalled = false;

                    const afterPrintHandler = () => {
                        if (isCallbackCalled) return;
                        isCallbackCalled = true;
                        win.close();
                        cb();
                    };

                    win.addEventListener('afterprint', afterPrintHandler);
                    win.print();
                })
                .catch((error) => {
                    console.error('Error loading styles:', error);
                    win.document.close();
                    win.focus();
                    win.print();
                    win.close();
                    cb();
                });
        };
    },
};

export default VueHtmlToPaper;
