
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FiltersConfiguration } from '@/helpers/ColorModule/ColorModuleHelper';
import { debounce } from 'vue-debounce';

@Component({
    name: 'ColorModuleSearch',
    components: {},
})
export default class ColorModuleSearch extends Vue {
    @Prop({ required: true }) private filtersActive!: boolean;
    @Prop({ required: true }) private requestFiltersActive!: boolean;
    @Prop({ required: true }) private activeTab!: string;
    @Prop({ required: true }) private filters!: any;
    @Prop({ required: true }) private searchQuery!: string;

    private isCacheResetIconLoading = false;

    private get FiltersConfiguration(): any {
        return FiltersConfiguration.filter((filter) => filter.isMandatory);
    }

    private get filterActiveState(): boolean {
        return this.activeTab === 'color-request' ? this.requestFiltersActive : this.filtersActive;
    }

    private debouncedColorSearch = debounce((event: any) => {
        this.onSearch(event.target.value, false);
    }, 50);

    private clearFilters() {
        this.$emit('clearFilters');
        this.activateSpinningAnimation();
    }

    private activateSpinningAnimation() {
        this.isCacheResetIconLoading = true;
        setTimeout(() => {
            this.isCacheResetIconLoading = false;
        }, 500);
    }

    private onSearch(query: string, shouldFetch: boolean = false) {
        this.$emit('search', { query, shouldFetch });
    }

    private onFilterChange(value: string, type: string, option: any) {
        this.$emit('filterChange', { value, type, id: option?.key || '' });
    }

    private onFilterActiveStateChange(val: boolean) {
        this.$emit('setFilters', val);
    }
}
