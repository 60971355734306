import { Model } from '@vuex-orm/core';
import ReminderOption from '@/models/ReminderOption';
import CMSUser from '@/models/CMSUser';
import NextStepType from '@/models/NextStepType';
import NextStepAPI from '@/api/NextStepAPI';
import NextStepRepository from '@/repositories/NextStepRepository';
import Project from '@/models/Project';

export default class NextStep extends Model {
    public static entity = 'nextStep';

    public static fields() {
        return {
            id: this.string(null),
            name: this.attr(''),
            dateFrom: this.attr(null),
            dateTo: this.attr(null),
            note: this.attr(''),
            updatedAt: this.attr(''),
            createdAt: this.attr(''),
            completed: this.boolean(false),
            reminderDate: this.attr(null),
            reminderOption_id: this.attr(null),
            project_id: this.attr(''),
            assignedUser_id: this.attr(''),
            type_id: this.attr(null),
            reminderOption: this.belongsTo(ReminderOption, 'reminderOption_id'),
            type: this.belongsTo(NextStepType, 'type_id'),
            assignedUser: this.belongsTo(CMSUser, 'assignedUser_id'),
            // project: this.belongsTo(Project, 'project_id'),
            madeByUser: this.belongsTo(CMSUser, 'madeByUser_id'),
            madeByUser_id: this.attr(null),
        };
    }

    public static async getAll() {
        let nextSteps;
        try {
            nextSteps = await NextStepAPI.getAll();
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextSteps.data,
            insertOrUpdate: [
                'assignedUser',
                'project',
                'client',
                'clientType',
                'nextStepType',
                'label',
                'country',
                'cmsUser',
            ],
        });
        return Promise.resolve();
    }

    public static async getById(nextStepId: string) {
        let nextStep;
        try {
            nextStep = await NextStepAPI.getById(nextStepId);
        } catch (e) {
            return Promise.reject(e);
        }
        this.create({
            data: nextStep.data,
            insertOrUpdate: [
                'reminderOption',
                'type',
                'assignedUser',
                'cmsUser',
                'campaign',
                'attachments',
                'nextStep',
            ],
        });
        return Promise.resolve(nextStep);
    }

    public static async updateExisting(nextStepId: string, nextStepInfo: any) {
        let nextStep;

        try {
            nextStep = await NextStepAPI.update(nextStepId, nextStepInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insertOrUpdate({
            data: nextStep.data,
            insertOrUpdate: [
                'reminderOption',
                'type',
                'assignedUser',
                'cmsUser',
                'campaign',
                'attachments',
                'nextStep',
                'project',
            ],
        });
        return Promise.resolve(nextStep);
    }

    public static async updateCompletedState(
        nextStepId: string,
        completedState: boolean,
        shouldUpdateLocalData = true
    ) {
        let nextStep;
        try {
            nextStep = await NextStepAPI.updateCompletedState(nextStepId, completedState);
        } catch (e) {
            return Promise.reject(e);
        }

        if (shouldUpdateLocalData) {
            this.insertOrUpdate({
                data: nextStep.data,
                insertOrUpdate: ['reminderOption', 'type', 'cmsUser', 'campaign', 'attachments', 'nextStep'],
            });
        }
        return Promise.resolve(nextStep);
    }

    public static async createNew(nextStepInfo: any) {
        let nextStep;

        try {
            nextStep = await NextStepAPI.create(nextStepInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        this.insert({
            data: nextStep.data,
            insertOrUpdate: ['reminderOption', 'type', 'cmsUser', 'campaign', 'attachments', 'nextStep', 'project'],
        });
        return Promise.resolve(nextStep);
    }

    public static async deleteExisting(nextStepId: string) {
        try {
            await NextStepAPI.delete(nextStepId);
        } catch (e) {
            return Promise.reject(e);
        }
        await NextStepRepository.delete(nextStepId);
        // remove it from table as well, if it exists
        await this.store().dispatch('tableData/removeNextStep', { nextStepId });
        return Promise.resolve();
    }

    public static async getFilteredProjects(query: string) {
        let nextSteps;
        try {
            nextSteps = await NextStepAPI.getFilteredProjects(query);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.create({
            data: nextSteps.data,
            insertOrUpdate: [
                'type',
                'assignedUser',
                'reminderOption',
                'project',
                'client',
                'clientType',
                'nextStepType',
                'label',
                'country',
                'cmsUser',
            ],
        });

        await this.store().dispatch('tableData/updateNextSteps', {
            sortedNextStepIds: nextSteps.data.map((nextStep: NextStep) => nextStep.id),
            nextSteps: NextStepRepository.getNextStepsWithProjects(),
        });

        return Promise.resolve(nextSteps.data);
    }

    public id!: string;
    public name!: string;
    public note!: string;
    public date!: string;
    public dateFrom!: string;
    public dateTo!: string;
    public completed!: boolean;
    public reminderDate!: string;
    public reminderOption!: ReminderOption;
    public type!: NextStepType;
    public assignedUser!: CMSUser;
    public madeByUser!: CMSUser;
    public project!: Project;
    // tslint:disable-next-line:variable-name
    public project_id!: string;
    public updatedAt!: string;
    public createdAt!: string;
}
