import { ActionTree } from 'vuex';
import ILocalState from './stateInterface';
import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { PriceItem } from '@/interfaces/general/PriceItem';
import { ConfiguratorValue } from '@/interfaces/components/configurator/ConfiguratorValue';
import { AxiosResponse } from 'axios';
import { formatFormData } from '@/helpers/FormDataHelper';
import { KeyedProductForm } from '@/interfaces/general/KeyedProductForm';
import i18n from '@/i18n';
import { OfferPriceDetail } from '@/interfaces/general/OfferPriceDetail';
import Product from '@/models/Product';
import OfferItem from '@/models/OfferItem';
import { checkErrorStateForOfferItems } from '@/helpers/OfferItemTableHelper';
import { ProductFormAPI } from '@/api/ProductFormAPI';
import { defaultValueWarnings } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import { updateRowNumbers } from '@/helpers/NewProject/ProductsContainerActionsHelper';

const actions: ActionTree<ILocalState, {}> = {
    async getSingleProductForm({ commit }, productFormId) {
        let data;

        try {
            data = await ProductFormAPI.getFromProduct(productFormId);
        } catch {
            return Promise.reject();
        }
        const formData = data.productForm;

        Product.insertOrUpdate({
            data,
            insertOrUpdate: ['image', 'uploadedPdf'],
        });

        commit('addProductForm', formData);

        return Promise.resolve();
    },

    async checkForErrors({ commit, getters }, { activeProductFormValues, productFormId, offerItemId }) {
        let errors;
        const formData: Array<{ id: string; value: ConfiguratorValue }> = formatFormData(activeProductFormValues);

        const request: any = {
            data: {
                type: 'form_data',
                attributes: {
                    productFormId,
                    offerItemId,
                    formData,
                },
            },
        };

        errors = (await ApiController.post(ApiRoutes.checkForErrors, request)) as AxiosResponse;

        return Promise.resolve({
            errors: errors.data.errors,
        });
    },

    async calculatePrice(
        { commit, getters },
        {
            activeProductFormValues,
            productFormId,
            clientId,
            currencySymbol,
            offerItemId,
            includePurchasePrice = false,
            projectId = null,
            // includeErrors = false,
        }
    ) {
        let price;
        const formData: Array<{ id: string; value: ConfiguratorValue }> = formatFormData(activeProductFormValues);

        const request: any = {
            data: {
                type: 'form_data',
                attributes: {
                    productFormId,
                    projectId,
                    offerItemId,
                    formData,
                },
            },
        };

        if (clientId !== '' && clientId != null) {
            request.data.attributes.clientId = clientId;
        }

        // let errors = null;

        try {
            price = (await ApiController.post(ApiRoutes.calculatePrice, request)) as AxiosResponse;
            //
            // if (includeErrors) {
            //     errors = await ApiController.post(ApiRoutes.checkForErrors, request) as AxiosResponse;
            // }
        } catch (e) {
            return Promise.reject(e);
        }

        if (price.request.response.meta != null) {
            return Promise.reject(price.request.response.meta);
        }

        const sellingPrices: PriceItem[] = price.data.sellingPriceDetails.map(
            ({ priceDetailName, priceDetailAmount, priceDetailAmountOriginal, isFixedPrice }: OfferPriceDetail) => {
                return {
                    label: priceDetailName,
                    priceValue: priceDetailAmountOriginal != null ? priceDetailAmountOriginal : priceDetailAmount,
                    isFixedPrice: isFixedPrice != null ? isFixedPrice : false,
                };
            }
        );

        price.data.sellingPriceManipulationDetails.forEach((sellingPriceManipulationDetails: any) => {
            sellingPrices.push({
                label: sellingPriceManipulationDetails.name,
                priceValue: sellingPriceManipulationDetails.amount,
                isFixedPrice: sellingPriceManipulationDetails.isFixedAmount,
            });
        });

        const roltekPrices: PriceItem[] = price.data.roltekPriceDetails.map(
            ({ priceDetailName, priceDetailAmount, priceDetailAmountOriginal, isFixedPrice }: OfferPriceDetail) => {
                return {
                    label: priceDetailName,
                    priceValue: priceDetailAmountOriginal != null ? priceDetailAmountOriginal : priceDetailAmount,
                    isFixedPrice: isFixedPrice != null ? isFixedPrice : false,
                };
            }
        );
        price.data.roltekPriceManipulationDetails.forEach((roltekPriceManipulationDetails: any) => {
            roltekPrices.push({
                label: roltekPriceManipulationDetails.name,
                priceValue: roltekPriceManipulationDetails.amount,
                isFixedPrice: roltekPriceManipulationDetails.isFixedAmount,
            });
        });

        let purchasePrices: PriceItem[] | undefined;
        if (price.data.purchasePriceDetails) {
            purchasePrices = price.data.purchasePriceDetails.map(
                ({ priceDetailName, priceDetailAmount, priceDetailAmountOriginal, isFixedPrice }: OfferPriceDetail) => {
                    return {
                        label: priceDetailName,
                        priceValue: priceDetailAmountOriginal != null ? priceDetailAmountOriginal : priceDetailAmount,
                        isFixedPrice: isFixedPrice != null ? isFixedPrice : false,
                    };
                }
            );
            if (purchasePrices) {
                price.data.purchasePriceManipulationDetails.forEach((priceManipulationDetails: any) => {
                    purchasePrices?.push({
                        label: priceManipulationDetails.name,
                        priceValue: priceManipulationDetails.amount,
                        isFixedPrice: priceManipulationDetails.isFixedAmount,
                    });
                });
            }
        }

        // todo - add to translations
        if (price.data.priceWithTax != null) {
            sellingPrices.push({
                label: i18n.t('Cijena') as string,
                priceValue: price.data.price,
            });
            sellingPrices.push({
                label: `${i18n.t('PDV')} (${price.data.tax}%)`,
                priceValue: price.data.priceWithTax - price.data.price,
            });
            sellingPrices.push({
                label: `${i18n.t('Kona\u010dna cijena')} (${currencySymbol})`,
                priceValue: price.data.priceWithTax,
            });
        } else {
            sellingPrices.push({
                label: `${i18n.t('Kona\u010dna cijena')} (${currencySymbol})`,
                priceValue: price.data.sellingPriceFinal,
            });
            roltekPrices.push({
                label: `${i18n.t('Kona\u010dna cijena')} (${currencySymbol})`,
                priceValue: price.data.roltekPriceFinal,
            });
            if (purchasePrices) {
                purchasePrices.push({
                    label: `${i18n.t('Kona\u010dna cijena')} (${currencySymbol})`,
                    priceValue: price.data.purchasePriceFinal,
                });
            }
        }

        // if (includeErrors && errors !== null) {
        //     if (includePurchasePrice) {
        //         return Promise.resolve({
        //             sellingPrices: prices,
        //             purchasePrices,
        //             errors: errors.data.errors,
        //         });
        //     }
        //
        //     return Promise.resolve({
        //         sellingPrices: prices,
        //         errors: errors.data.errors,
        //     });
        // }

        if (includePurchasePrice) {
            return Promise.resolve({
                sellingPrices,
                purchasePrices,
                roltekPrices,
            });
        }

        return Promise.resolve({
            sellingPrices,
            roltekPrices,
        });
    },
    addActiveProductForm({ commit, getters }, payload) {
        const id = getters.activeProductForms.length;
        commit('addActiveProductForm', {
            id,
            activeProductForm: payload,
        });
        return Promise.resolve(id);
    },

    updateActiveProductFormValue({ commit, getters }, payload) {
        const filteredProductFormPosition: number = getters.activeProductForms.findIndex(
            (activeProductForm: KeyedProductForm) => {
                return activeProductForm.id === payload.productFormId;
            }
        );
        if (filteredProductFormPosition === -1) {
            return;
        }

        commit('updateActiveProductFormValue', {
            id: payload.pId,
            value: payload.value,
            position: filteredProductFormPosition,
        });
        return Promise.resolve();
    },

    async updateProductForms() {
        try {
            await ApiController.get(ApiRoutes.updateProductForms);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importProductForms() {
        try {
            await ApiController.get(ApiRoutes.importProductForms);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importJavascriptFunctions() {
        try {
            await ApiController.get(ApiRoutes.importJavascriptFunctions);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importMaterials() {
        try {
            await ApiController.get(ApiRoutes.importMaterials);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importPriceLists() {
        try {
            await ApiController.get(ApiRoutes.importPriceLists);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importPriceListTypes() {
        try {
            await ApiController.get(ApiRoutes.importPriceListTypes);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importDiscounts() {
        try {
            await ApiController.get(ApiRoutes.importDiscounts);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importTechnicalDocuments() {
        try {
            await ApiController.get(ApiRoutes.importTechnicalDocuments);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async importColors() {
        try {
            await ApiController.get(ApiRoutes.importColors);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async toggleColorImporting() {
        try {
            await ApiController.get(ApiRoutes.toggleColorImport);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async getColorImportingStatus({ commit }) {
        try {
            const isImporting = await ApiController.get(ApiRoutes.colorImportStatus);

            commit('setImportingStatus', isImporting.data.is_running);
        } catch (e) {
            throw e;
        }

        return Promise.resolve();
    },

    async addInitialOfferItems({ commit, getters }, offerItems: OfferItem[]) {
        const payload = offerItems.map((offerItem) => {
            return {
                offerItemId: offerItem.id,
                rowNumber: offerItem.displayRowNumber,
                name: offerItem.name,
                oldPrice: offerItem.offerItemPrice.finalSellingPrice,
                status: 'loading',
                frontendErrors: [],
                backendErrors: [],
                newPrice: null,
                activeProductFormId: null,
                productId: offerItem.productSystem?.product.id,
            };
        });

        commit('addInitialOfferItems', payload);
    },

    async updateRecalculationErrorsWithDefaultValueWarnings({ commit }) {
        commit('updateRecalculationErrorsWithDefaultValueWarnings');
    },

    async updateOfferItem({ commit }, options: any) {
        commit('updateOfferItem', options);
    },
    async updateRowNumbers({ commit }, rowNumbersAndOfferItemIds: any) {
        commit('updateRowNumbers', rowNumbersAndOfferItemIds);
    },
    async updateOfferErrorState({ commit }, offerItems: any) {
        const errorState = checkErrorStateForOfferItems(offerItems);

        commit('updateOfferErrorState', errorState);
    },

    async setDefaultValueWarnings({ commit }, options: defaultValueWarnings[]) {
        commit('setDefaultValueWarnings', options);
    },
    async setCustomValuesOverride({ commit }, options: any[]) {
        commit('setCustomValuesOverride', options);
    },
    async updateOfferPriceValidState({ commit }, offerPricesHaveErrors: boolean) {
        commit('updateOfferPriceValidState', offerPricesHaveErrors);
    },
};

export default actions;
