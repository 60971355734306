
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Color } from '@/interfaces/components/color/Color';
import { ColorTableData } from '@/interfaces/components/color/ColorTableData';

@Component({
    name: 'ColorModuleTable',
    components: {},
})
export default class ColorModuleTable extends Vue {
    @Prop({ required: false }) private searchQuery!: string;
    @Prop({ required: true }) private currentCollection!: string;

    private get colors(): Array<Color> {
        return this.$store.getters['colorData/colors'];
    }

    removePaginationTooltips() {
        const paginationItems = document.querySelectorAll(
            '.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next'
        );
        paginationItems.forEach((item) => {
            item.removeAttribute('title'); // Nuke the tooltip behavior
        });
    }

    private get columns() {
        return [
            {
                dataIndex: 'color',
                key: 'color',
                scopedSlots: { customRender: 'color' },
                slots: { title: 'colorTitle' }, // Use `slots` for title
                width: 70,
            },
            {
                dataIndex: 'colorName',
                key: 'colorName',
                scopedSlots: { customRender: 'colorName' },
                slots: { title: 'colorNameTitle' }, // Use `slots` for title
                width: 300,
            },
            { title: this.$t('Gloss/Surface'), dataIndex: 'colorSurface', key: 'colorSurface', width: 150 },
            { title: this.$t('Manufacturer’s powder ID'), dataIndex: 'powderId', key: 'powderId', width: 370 },
            {
                title: this.$t(''),
                dataIndex: 'select',
                key: 'select',
                scopedSlots: { customRender: 'select' },
                width: 80,
            },
        ];
    }

    private get colorsData() {
        if (!this.colors.length) {
            return [];
        }

        const colors: ColorTableData[] = [];

        for (let i = 0; i < this.colors.length; i++) {
            const color = this.colors[i];

            if (!color?.powders?.length) {
                colors.push({
                    key: color.id,
                    id: color.id,
                    color: color?.hex,
                    colorName: color?.name,
                    colorSurface: '-',
                    powderId: '-',
                });

                continue;
            }

            for (let j = 0; j < color.powders.length; j++) {
                const powder = color.powders[j];
                const isRoltekColor = powder?.id === color?.defaultPowder?.id && !!powder.colorCollection;
                const groupName = powder?.colorCollection?.groupName;
                const isStandard = isRoltekColor && groupName === 'STANDARD';

                if (
                    (this.currentCollection === 'STANDARD' && isStandard) ||
                    (this.currentCollection === 'INDIVIDUAL' && !isStandard) ||
                    this.currentCollection === ''
                ) {
                    colors.push({
                        key: color.id + '-' + powder?.id,
                        id: color.id,
                        color: color?.hex,
                        colorName: color?.name,
                        powderId: powder?.name,
                        selectedPowder: powder?.id,
                        colorSurface: powder?.colorSurface?.name ?? '-',
                        groupName,
                        isRoltekColor,
                        isStandard,
                    });
                }
            }
        }

        if (!this.searchQuery) {
            return colors;
        }

        return this.filterColorsBySeachQuery(colors);
    }

    private filterColorsBySeachQuery(colors: ColorTableData[]) {
        return colors.filter((color: ColorTableData) => {
            switch (true) {
                case color.colorName.toLowerCase().includes(this.searchQuery.toLowerCase()):
                    return true;
                case color.colorSurface.toLowerCase().includes(this.searchQuery.toLowerCase()):
                    return true;
                case color.powderId.toLowerCase().includes(this.searchQuery.toLowerCase()):
                    return true;
                default:
                    return false;
            }
        });
    }

    private selectColor(selectedColor: ColorTableData) {
        const color = this.colors.find((currentColor: Color) => currentColor.id === selectedColor.id);

        this.$emit('selectColor', {
            color,
            selectedPowderId: selectedColor.selectedPowder,
            isRoltekColor: selectedColor.isRoltekColor,
            groupName: selectedColor.groupName,
        });
    }

    private mounted() {
        this.removePaginationTooltips();
    }
}
