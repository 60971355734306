
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChecklistDropdownField } from '@/interfaces/components/configurator/ChecklistDropdownField';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Getter } from 'vuex-class';
import {
    extractAndRunOptionsFunction,
    focusDropdownElement,
    mapVisibleOptionVIDsToChecklistDropdownOptions,
} from '@/helpers/FieldFunctionHelper';
import { ActiveProductFormValueObject } from '@/interfaces/general/ActiveProductFormValueObject';
import { EventBus } from '@/helpers/EventBusHelper';
import { Select } from 'ant-design-vue';
import { defaultValueWarnings as defaultValueWarningsConfig } from '@/interfaces/components/projectNew/DefaultValueWarnings';
import { GlobalOptions } from '@/enums/global/GlobalOptions';

@Component({
    name: 'ChecklistDropdownFieldModule',
})
export default class ChecklistDropdownFieldModule extends Vue {
    @Prop({ required: true })
    private checklistDropdownField!: ChecklistDropdownField;
    @Prop({ required: true }) private pId!: string;
    @Prop({ default: 999 }) private itemRowIndex!: number;
    @Prop({ default: false }) private isDisabled!: boolean;
    @Prop({ required: true }) private image!: string | null;
    @Prop({ default: null }) private debounceToggleIsActive!: ((state: boolean) => void) | null;
    @Prop({ default: false }) private allowAllDropdownOptions!: boolean | null;
    @Prop({ default: null }) private activeProductFormId!: number | null;
    @Prop({ default: false }) private isConfigurator!: boolean;
    @Getter('configurator/activeProductFormValue') private productFormValue!: (
        pId: string,
        productFormId: number
    ) => string | null;

    private value = '#';
    private visibleOptionVIDs: string[] = [];
    private isOpen = false;
    private isWidthDynamicallyAdjusted = false;
    private globalOptions = GlobalOptions;
    private svgSource = require('../../../../public/color-picker.svg');

    private get dropdownClassName() {
        return `c-dropdown-${this.pId}-${this.itemRowIndex}`;
    }

    private get visibleDropdownOptions() {
        if (this.visibleOptionVIDs.length <= 0) {
            return this.checklistDropdownField.checklistDropdownOptions;
        }

        return mapVisibleOptionVIDsToChecklistDropdownOptions(
            this.visibleOptionVIDs,
            this.checklistDropdownField.checklistDropdownOptions
        );
    }

    private get defaultValueWarnings() {
        const warnings = this.$store.getters['configurator/defaultValueWarnings'];

        if (warnings.length) {
            return warnings.find(
                (warning: defaultValueWarningsConfig) => warning.productFormId === this.activeProductFormId
            );
        }

        return null;
    }

    private get fieldValue() {
        let value: string | null = '';
        if (this.activeProductFormId == null) {
            this.value = value;
            return '';
        }

        value = this.productFormValue(this.pId, this.activeProductFormId);

        if (value == null) {
            this.value = '';
            return;
        }
        this.value = value;

        return value;
    }

    private get shouldShowAsButtons() {
        return this.visibleDropdownOptions.length <= 3;
    }

    private get isRoltekUserGroup(): boolean {
        return this.$store.getters['jwtData/isRoltekUserGroup'];
    }

    public runVisibleOptionVIDs({
        activeProductFormValues,
        activeProductFormId,
    }: {
        activeProductFormValues: ActiveProductFormValueObject;
        activeProductFormId: number;
    }) {
        if (activeProductFormId !== this.activeProductFormId) {
            return;
        }

        if (
            this.checklistDropdownField.optionsFunction == null ||
            this.checklistDropdownField.optionsFunctionData == null ||
            this.allowAllDropdownOptions
        ) {
            this.visibleOptionVIDs = [];
            return;
        }
        this.visibleOptionVIDs = extractAndRunOptionsFunction(
            this.checklistDropdownField.optionsFunction,
            activeProductFormValues,
            this.checklistDropdownField.optionsFunctionData
        ).split(';');
        // Testing performance without javascript calculations
        // this.visibleOptionVIDs = [];

        if (this.fieldValue == null || this.visibleOptionVIDs.includes(this.fieldValue)) {
            return;
        }

        this.onChange(this.visibleOptionVIDs[0]);
    }

    private async onDropdownVisibleChange(state: boolean) {
        this.isOpen = state;

        if (!state) {
            this.revertDefaultWidthConfigurationForDropdownElements();

            return;
        }

        await this.$nextTick();

        this.setFlexibleWidthForDropdownElements();
    }

    private setFlexibleWidthForDropdownElements() {
        const activeDropdownEl = document.querySelector(`.${this.dropdownClassName}`) as HTMLElement;
        const viewportWidth = window.innerWidth;
        const availableSpaceToTheRight = (viewportWidth - activeDropdownEl.getBoundingClientRect().right) as number;

        if (availableSpaceToTheRight >= 0) {
            return;
        }

        const elWidth = activeDropdownEl.offsetWidth;
        const newWidth = String(elWidth + availableSpaceToTheRight) + 'px';
        activeDropdownEl.style.width = newWidth;

        activeDropdownEl.querySelectorAll('.c-dropdown__value').forEach((element: any) => {
            element.style['white-space'] = 'pre-wrap';
            element.style['word-wrap'] = 'break-word';
        });

        this.isWidthDynamicallyAdjusted = true;
    }

    private revertDefaultWidthConfigurationForDropdownElements() {
        if (!this.isWidthDynamicallyAdjusted) {
            return;
        }

        const activeDropdownEl = document.querySelector(`.${this.dropdownClassName}`) as HTMLElement;
        activeDropdownEl.style.width = 'auto';

        activeDropdownEl.querySelectorAll('.c-dropdown__value').forEach((element: any) => {
            element.style['white-space'] = 'nowrap';
            element.style['word-wrap'] = 'normal';
        });
    }

    private async onOpenDropdown(event: InputEvent) {
        const isTargetElementRoot = (event.target as HTMLElement).classList.contains('c-dropdown');

        // must check if the target element is root because otherwise it might open when user is selecting an option
        if (this.isOpen || !isTargetElementRoot) {
            return;
        }

        this.isOpen = true;

        await focusDropdownElement(event, this.$refs.dropdown as Select);
    }

    private onSelect() {
        EventBus.$emit(EventBusEvents.changesInDataMade, { state: true });
        window.setTimeout(() => {
            this.debounceToggleIsActive && this.debounceToggleIsActive(true);
        }, 200);
    }

    private onCustomDropdownSelect(value: string) {
        console.log(value);
        this.value = value;
        this.onChange(value);
        // this.isOpen = false;

        this.onSelect();
    }

    private onChange(checked: string) {
        this.$emit(EventBusEvents.updateStoreFieldValue, {
            pId: this.pId,
            value: checked,
            productFormId: this.activeProductFormId,
        });
    }

    private onBlur() {
        this.isOpen = false;
        this.debounceToggleIsActive && this.debounceToggleIsActive(false);
    }

    private onFocus() {
        window.setTimeout(() => {
            this.debounceToggleIsActive && this.debounceToggleIsActive(true);
        }, 200);
    }

    private updateColorValue(payload: any) {
        if (payload.fieldToBeUpdatedPID !== this.pId) {
            return;
        }

        this.value = payload.fieldValue;
        this.onChange(payload.fieldValue);

        if (payload.selectedColorValue) {
            this.$nextTick(() => {
                this.$emit(EventBusEvents.updateStoreFieldValue, {
                    pId: payload.colorDropdownPID,
                    value: payload.selectedColorValue as string,
                });
            });
        }
    }

    private openColorModule(payload: {
        checklistFieldPID: string;
        fieldValue: string;
        activeProductFormId: number | null;
    }) {
        EventBus.$emit(EventBusEvents.showColorModule, payload);
    }

    private created() {
        // @ts-ignore
        // console.log('mountedddddddd', this._uid, 'wtf');
        EventBus.$on(EventBusEvents.triggerOptionsFunctions, this.runVisibleOptionVIDs);

        if (this.activeProductFormId === null) {
            EventBus.$on(EventBusEvents.updateMultipositionHeaderSystemColorValue, this.updateColorValue);
        }
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.triggerOptionsFunctions, this.runVisibleOptionVIDs);

        if (this.activeProductFormId === null) {
            EventBus.$off(EventBusEvents.updateMultipositionHeaderSystemColorValue, this.updateColorValue);
        }
    }

    @Watch(`fieldValue`, { immediate: true })
    private onFieldValueChange(newValue: string | null, oldValue: string | null) {
        if (newValue === oldValue) {
            return;
        }
        if (newValue == null) {
            this.value = '';
            return;
        }

        this.value = newValue;
    }
}
