import CMSUser from '@/models/CMSUser';
import VuexORM from '@vuex-orm/core';
import User from '@/models/User';
import Project from '@/models/Project';
import Lead from '@/models/Lead';
import ProductCategory from '@/models/ProductCategory';
import Product from '@/models/Product';
import Offer from '@/models/Offer';
import OfferItem from '@/models/OfferItem';
import Client from '@/models/Client';
import PaymentType from '@/models/PaymentType';
import Pdf from '@/models/Pdf';
import Montage from '@/models/Montage';
import Country from '@/models/Country';
import PriceListRegion from '@/models/PriceListRegion';
import PriceType from '@/models/PriceType';
import ProductCatalogue from '@/models/ProductCatalogue';
import Image from '@/models/Image';
import Translation from '@/models/Translation';
import TranslationalObject from '@/models/TranslationalObject';
import Label from '@/models/Label';
import Locale from '@/models/Locale';
import ChecklistField from '@/models/ChecklistField';
import ProductSystemDetails from '@/models/ProductSystemDetails';
import ChecklistSegment from '@/models/ChecklistSegment';
import Checklist from '@/models/Checklist';
import ProductForm from '@/models/ProductForm';
import UploadedPdf from '@/models/UploadedPdf';
import ProjectState from '@/models/ProjectState';
import ProductSystemDetailsConfiguration from '@/models/ProductSystemDetailsConfiguration';
import Token from '@/models/Token';
import CampaignType from '@/models/CampaignType';
import Campaign from '@/models/Campaign';
import LeadOrigin from '@/models/LeadOrigin';
import LeadProduct from '@/models/LeadProduct';
import LeadType from '@/models/LeadType';
import NextStepType from '@/models/NextStepType';
import Attachment from '@/models/Attachment';
import ClientType from '@/models/ClientType';
import Address from '@/models/Address';
import ReminderOption from '@/models/ReminderOption';
import PriceManipulationType from '@/models/PriceManipulationType';
import ProjectLog from '@/models/ProjectLog';
import JsFunction from '@/models/JsFunction';
import Tax from '@/models/Tax';
import DocumentName from '@/models/DocumentName';
import UserGroup from '@/models/UserGroup';
import OfferConfiguration from '@/models/OfferConfiguration';
import PostCodeConnection from '@/models/PostCodeConnection';
import UserGroupConnection from '@/models/UserGroupConnection';
import Currency from '@/models/Currency';
import ProductSystem from '@/models/ProductSystem';
import OrderStatus from '@/models/OrderStatus';
import ContactPerson from '@/models/ContactPerson';
import NextStep from '@/models/NextStep';
import OfferPdfProperties from '@/models/OfferPdfProperties';
import AwardImage from '@/models/AwardImage';
import HeaderImage from '@/models/HeaderImage';
import IntroScreen from '@/models/IntroScreen';
import IntroScreenItem from '@/models/IntroScreenItem';
import OfferTitle from '@/models/OfferTitle';
import BankAccount from '@/models/BankAccount';
import TaxDetails from '@/models/TaxDetails';
import Discount from '@/models/Discount';
import PlainMessage from '@/models/PlainMessage';
import ChatMessage from '@/models/ChatMessage';
import Chat from '@/models/Chat';
import OfferItemPriceManipulation from '@/models/OfferItemPriceManipulation';
import OfferItemPrice from '@/models/OfferItemPrice';
import OfferItemPriceType from '@/models/OfferItemPriceType';
import SellingPriceManipulation from '@/models/SellingPriceManipulation';
import PurchasePriceManipulation from '@/models/PurchasePriceManipulation';
import OfferPrice from '@/models/OfferPrice';
import PaymentRecord from '@/models/PaymentRecord';
import Workflow from '@/models/Workflow';
import WorkflowNode from '@/models/WorkflowNode';
import WorkflowNodeConnection from '@/models/WorkflowNodeConnection';
import SystemWorkflowNode from '@/models/SystemWorkflowNode';
import ProjectLabelWorkflowNode from '@/models/ProjectLabelWorkflowNode';
import ProjectLabelTemplate from '@/models/ProjectLabelTemplate';
import ProjectLabelTemplateWorkflowNode from '@/models/ProjectLabelTemplateWorkflowNode';
import WorkflowTemplate from '@/models/WorkflowTemplate';
import EmailTemplateProperty from '@/models/EmailTemplateProperty';
import AutomaticPriceManipulation from '@/models/AutomaticPriceManipulation';
import ProductGroup from '@/models/ProductGroup';
import UserAlias from '@/models/UserAlias';
import OrderConfiguration from '@/models/OrderConfiguration';
import OrderPdfProperties from '@/models/OrderPdfProperties';
import OrderPdf from '@/models/OrderPdf';
import ClientAttachment from '@/models/ClientAttachment';
import FlaggedPdfValue from '@/models/FlaggedPdfValue';
import DropdownOption from '@/models/DropdownOption';
import CustomerHistory from '@/models/CustomerHistory';
import Schedule from '@/models/Schedule';
import Resource from '@/models/Resource';
import Report from '@/models/Report';
import ReportTypes from '@/models/ReportTypes';
import Dashboard from '@/models/Dashboard';
import DashboardReport from '@/models/DashboardReport';
import News from '@/models/News';
import NewsAttachment from '@/models/NewsAttachment';
import StatusEvent from '@/models/StatusEvent';
import NextStepTemplate from '@/models/NextStepTemplate';
import ProductSystemWizard from '@/models/ProductSystemWizard';
import ProductSystemWizardTranslations from '@/models/ProductSystemWizardTranslation';
import WarrantyWarning from '@/models/WarrantyWarning';
import Rule from '@/models/Rule';
import ProjectSearch from '@/models/ProjectSearch';
import Notification from '@/models/Notification';
import ProductVersion from '@/models/ProductVersion';
import ProductMaterial from '@/models/ProductMaterial';
import ProductFunctions from '@/models/ProductFunctions';
import NotificationSettings from '@/models/NotificationSettings';
import OfferNote from '@/models/OfferNote';
import TechnologistOrder from '@/models/TechnologistOrder';
import TransferredOrder from '@/models/TransferredOrder';

const vuexORMDatabase = new VuexORM.Database();

vuexORMDatabase.register(User);
vuexORMDatabase.register(Project);
vuexORMDatabase.register(Lead);
vuexORMDatabase.register(ProductCategory);
vuexORMDatabase.register(Product);
vuexORMDatabase.register(OfferItem);
vuexORMDatabase.register(Offer);
vuexORMDatabase.register(Client);
vuexORMDatabase.register(PaymentType);
vuexORMDatabase.register(CMSUser);
vuexORMDatabase.register(Token);
vuexORMDatabase.register(Pdf);
vuexORMDatabase.register(OrderPdf);
vuexORMDatabase.register(Montage);
vuexORMDatabase.register(Country);
vuexORMDatabase.register(PriceListRegion);
vuexORMDatabase.register(PriceType);
vuexORMDatabase.register(ProductCatalogue);
vuexORMDatabase.register(Image);
vuexORMDatabase.register(Translation);
vuexORMDatabase.register(TranslationalObject);
vuexORMDatabase.register(Label);
vuexORMDatabase.register(ProductSystemDetails);
vuexORMDatabase.register(Locale);
vuexORMDatabase.register(ChecklistField);
vuexORMDatabase.register(ChecklistSegment);
vuexORMDatabase.register(Checklist);
vuexORMDatabase.register(ProductForm);
vuexORMDatabase.register(UploadedPdf);
vuexORMDatabase.register(ProjectState);
vuexORMDatabase.register(ProductSystemDetailsConfiguration);
vuexORMDatabase.register(CampaignType);
vuexORMDatabase.register(Campaign);
vuexORMDatabase.register(LeadOrigin);
vuexORMDatabase.register(LeadProduct);
vuexORMDatabase.register(LeadType);
vuexORMDatabase.register(NextStepType);
vuexORMDatabase.register(Attachment);
vuexORMDatabase.register(ClientAttachment);
vuexORMDatabase.register(ClientType);
vuexORMDatabase.register(Address);
vuexORMDatabase.register(ReminderOption);
vuexORMDatabase.register(SellingPriceManipulation);
vuexORMDatabase.register(PurchasePriceManipulation);
vuexORMDatabase.register(PriceManipulationType);
vuexORMDatabase.register(ProjectLog);
vuexORMDatabase.register(JsFunction);
vuexORMDatabase.register(Tax);
vuexORMDatabase.register(DocumentName);
vuexORMDatabase.register(UserGroup);
vuexORMDatabase.register(OfferConfiguration);
vuexORMDatabase.register(OrderConfiguration);
vuexORMDatabase.register(PostCodeConnection);
vuexORMDatabase.register(UserGroupConnection);
vuexORMDatabase.register(Currency);
vuexORMDatabase.register(ProductSystem);
vuexORMDatabase.register(OrderStatus);
vuexORMDatabase.register(ContactPerson);
vuexORMDatabase.register(NextStep);
vuexORMDatabase.register(OfferPdfProperties);
vuexORMDatabase.register(OrderPdfProperties);
vuexORMDatabase.register(AwardImage);
vuexORMDatabase.register(HeaderImage);
vuexORMDatabase.register(IntroScreen);
vuexORMDatabase.register(IntroScreenItem);
vuexORMDatabase.register(OfferTitle);
vuexORMDatabase.register(BankAccount);
vuexORMDatabase.register(TaxDetails);
vuexORMDatabase.register(Discount);
vuexORMDatabase.register(PlainMessage);
vuexORMDatabase.register(ChatMessage);
vuexORMDatabase.register(Chat);
vuexORMDatabase.register(OfferItemPriceManipulation);
vuexORMDatabase.register(OfferItemPrice);
vuexORMDatabase.register(OfferItemPriceType);
vuexORMDatabase.register(OfferPrice);
vuexORMDatabase.register(PaymentRecord);
vuexORMDatabase.register(Workflow);
vuexORMDatabase.register(WorkflowTemplate);
vuexORMDatabase.register(WorkflowNode);
vuexORMDatabase.register(WorkflowNodeConnection);
vuexORMDatabase.register(SystemWorkflowNode);
vuexORMDatabase.register(ProjectLabelWorkflowNode);
vuexORMDatabase.register(ProjectLabelTemplate);
vuexORMDatabase.register(ProjectLabelTemplateWorkflowNode);
vuexORMDatabase.register(EmailTemplateProperty);
vuexORMDatabase.register(AutomaticPriceManipulation);
vuexORMDatabase.register(ProductGroup);
vuexORMDatabase.register(UserAlias);
vuexORMDatabase.register(FlaggedPdfValue);
vuexORMDatabase.register(DropdownOption);
vuexORMDatabase.register(CustomerHistory);
vuexORMDatabase.register(Schedule);
vuexORMDatabase.register(Resource);
vuexORMDatabase.register(Report);
vuexORMDatabase.register(ReportTypes);
vuexORMDatabase.register(Dashboard);
vuexORMDatabase.register(DashboardReport);
vuexORMDatabase.register(News);
vuexORMDatabase.register(NewsAttachment);
vuexORMDatabase.register(StatusEvent);
vuexORMDatabase.register(NextStepTemplate);
vuexORMDatabase.register(ProductSystemWizard);
vuexORMDatabase.register(ProductSystemWizardTranslations);
vuexORMDatabase.register(WarrantyWarning);
vuexORMDatabase.register(Rule);
vuexORMDatabase.register(ProjectSearch);
vuexORMDatabase.register(Notification);
vuexORMDatabase.register(ProductVersion);
vuexORMDatabase.register(ProductMaterial);
vuexORMDatabase.register(ProductFunctions);
vuexORMDatabase.register(NotificationSettings);
vuexORMDatabase.register(OfferNote);
vuexORMDatabase.register(TechnologistOrder);
vuexORMDatabase.register(TransferredOrder);

export { vuexORMDatabase };
