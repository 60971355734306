import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import ILocalState from './stateInterface';

const initState: ILocalState = {
    colors: [],
    powders: [],
    prices: [],
    surfaces: [],
    qualities: [],
    glosses: [],
    effects: [],
    charts: [],
    collections: [],
};

export default {
    namespaced: true,
    state: initState,
    getters,
    actions,
    mutations,
};
