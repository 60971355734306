import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteGuardService } from '@/services/RouteGuardService';
import { RouteNames } from '@/enums/routes/RouteNames';
import { NavigationKeys } from '@/enums/global/NavigationKeys';
Vue.use(VueRouter);

const getMetaForAuthorizedRoutes = (navigationKey = '', shouldShowNavSideBar = true) => {
    return {
        meta: {
            navigationLayout: true,
            authRequired: true,
            navigationKey,
            shouldShowNavSideBar,
        },
    };
};

const routes = [
    {
        path: `/${RouteNames.login}`,
        name: RouteNames.login,
        component: () => import('@/views/Login.vue'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        name: RouteNames.home,
        component: () => import('@/views/Home.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.PROJECTS),
    },
    {
        path: `/calculator`,
        name: RouteNames.calculator,
        component: () => import('@/views/Calculator.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CALCULATOR),
        children: [
            {
                path: ``,
                name: RouteNames.calculator,
                component: () => import('@/views/CalculatorMainSection.vue'),
                ...getMetaForAuthorizedRoutes(NavigationKeys.CALCULATOR),
            },
            {
                path: `:productFormId/:productId`,
                name: RouteNames.calculatorProduct,
                component: () => import('@/views/CalculatorProductView.vue'),
                ...getMetaForAuthorizedRoutes(NavigationKeys.CALCULATOR),
            },
        ],
    },
    {
        path: `/${RouteNames.project}/:id?`,
        name: RouteNames.project,
        component: () => import('@/views/NewProjectView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.PROJECTS),
    },
    {
        path: `/${RouteNames.project}/phone/:id?`,
        name: RouteNames.phoneProject,
        component: () => import('@/views/PhoneProjectView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.PROJECTS),
    },
    {
        path: `/${RouteNames.configurator}/:productFormId/:productId/:offerItemId?`,
        name: RouteNames.configurator,
        component: () => import('@/views/ProductView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.PROJECTS),
    },
    {
        path: `/${RouteNames.error}`,
        name: RouteNames.error,
        component: () => import('@/views/ErrorView.vue'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: `/${RouteNames.clientNewInquiry}`,
        name: RouteNames.clientNewInquiry,
        component: () => import('@/views/ClientNewInquiry.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CLIENTS),
    },
    {
        path: `/${RouteNames.client}`,
        name: RouteNames.client,
        component: () => import('@/views/ClientView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CLIENTS),
    },
    {
        path: `/${RouteNames.client}/:clientId`,
        name: RouteNames.editClient,
        component: () => import('@/views/NewEditClientView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CLIENTS),
    },
    {
        path: `/${RouteNames.client}/new`,
        name: RouteNames.createClient,
        component: () => import('@/views/NewEditClientView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CLIENTS),
    },
    {
        path: `/translations`,
        name: RouteNames.translationList,
        component: () => import('@/views/Translations.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
        children: [
            {
                path: `/translations/:entityName/:attribute`,
                name: RouteNames.translation,
                component: () => import('@/views/TranslationScreen.vue'),
                ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
            },
        ],
    },
    {
        path: `/translations/:entityName/:attribute`,
        name: RouteNames.translation,
        component: () => import('@/views/TranslationScreen.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/settings`,
        name: RouteNames.settings,
        component: () => import('@/views/Settings.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/content`,
        name: RouteNames.cmsContent,
        component: () => import('@/views/CmsContent.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/content/:subCategory`,
        name: RouteNames.contentSubcategory,
        component: () => import('@/views/CmsContent.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/settings/:entityName`,
        name: RouteNames.cmsUsers,
        component: () => import('@/views/CmsUsers.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/cms/:entityName`,
        name: RouteNames.cmsShow,
        component: () => import('@/views/CmsShow.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
        children: [
            {
                path: `/cms/:entityName/:entityId`,
                name: RouteNames.cmsIndex,
                component: () => import('@/views/CmsIndex.vue'),
                ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
            },
        ],
    },
    {
        path: `/statistics`,
        name: RouteNames.cmsStatistics,
        component: () => import('@/views/StatisticsModuleCms.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/dashboards`,
        name: RouteNames.dashboards,
        component: () => import('@/views/Dashboards.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/dashboard/:dashboardId?`,
        name: RouteNames.statisticsDashboard,
        component: () => import('@/views/StatisticsDashboard.vue'),
        ...getMetaForAuthorizedRoutes('', false),
    },
    {
        path: `/news`,
        name: RouteNames.cmsNews,
        component: () => import('@/views/NewsModuleCms.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/reset-password/:token`,
        name: RouteNames.resetPassword,
        component: () => import('@/views/ResetPassword.vue'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: `/forgot-password`,
        name: RouteNames.forgotPassword,
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: `/custom/search/:type`,
        name: RouteNames.customSearch,
        component: () => import('@/views/CustomSearch.vue'),
        meta: { navigationLayout: false, authRequired: false },
    },
    {
        path: `/chat/:id?`,
        name: RouteNames.chat,
        component: () => import('@/views/ChatView.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CHAT),
    },
    {
        path: `/manual-api`,
        name: RouteNames.manualAPI,
        component: () => import('@/views/ManualAPIRefresh.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/product-versions`,
        name: RouteNames.productVersions,
        component: () => import('@/views/ProductVersions.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/transferred-orders`,
        name: RouteNames.transferredOrders,
        component: () => import('@/views/XmlOrders.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/notifications`,
        name: RouteNames.notifications,
        component: () => import('@/views/Notifications.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/technical-documentation`,
        name: RouteNames.technicalDocumentation,
        component: () => import('@/views/TechnicalDocumentation.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.DOCUMENTS),
    },
    {
        path: `/calendar`,
        name: RouteNames.calendar,
        component: () => import('@/views/Calendar.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CALENDAR),
    },
    {
        path: `/workflow`,
        name: RouteNames.workflow,
        component: () => import('@/views/WorkflowView.vue'),
        meta: { authRequired: true, navigationLayout: true },
    },
    {
        path: `/automatic-events`,
        name: RouteNames.automaticEvents,
        component: () => import('@/views/AutomaticEvents.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.SETTINGS),
    },
    {
        path: `/workflow-template`,
        name: RouteNames.workflowTemplate,
        component: () => import('@/views/WorkflowTemplateView.vue'),
        meta: { authRequired: true, navigationLayout: true },
    },
    {
        path: `/schedueles-resources/:dynamic`,
        name: RouteNames.schedulesResources,
        component: () => import('@/views/ScheduleResources.vue'),
        meta: { authRequired: true, navigationLayout: true },
    },
    {
        path: `/new-product-system-wizard/:dynamic`,
        name: RouteNames.newProductSystemWizard,
        component: () => import('@/components/views/ProductSystemWizard/NewProductSystemWizard.vue'),
        meta: { authRequired: true, navigationLayout: true },
    },
    {
        path: `*`,
        redirect: `/${RouteNames.error}`,
    },
    {
        path: `/register`,
        name: RouteNames.register,
        component: () => import('@/views/RegisterForm.vue'),
        meta: { authRequired: false },
    },
    {
        path: `/client-screen/:clientId`,
        name: RouteNames.clientScreen,
        component: () => import('@/views/ShinyCall.vue'),
        ...getMetaForAuthorizedRoutes(NavigationKeys.CLIENTS),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
const routeGuard = new RouteGuardService(router);

export default router;
