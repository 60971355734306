import { SearchEntityFilter } from '@/interfaces/SearchEntityFilter';
import { ProjectTypes } from '@/enums/models/ProjectTypes';
import { formatDate } from '@/helpers/CmsIndexHelper';
import { FilterUrl } from '@/interfaces/general/FilterUrl';
import { ElementFilterType } from '@/enums/global/ElementFilterType';
import { LocalStorageService } from '@/services/LocalStorageService';
import { PredefinedCustomSearchParameters } from '@/interfaces/general/PredefinedCustomSearchParameters';
import { getAppropriateFilterOptions } from '@/helpers/NewProjectSearchBarHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Project from '@/models/Project';
import { SortOrder } from 'ant-design-vue/types/table/column';
import Client from '@/models/Client';
import User from '@/models/User';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import OrderStatus from '@/models/OrderStatus';
import ClientType from '@/models/ClientType';
import LeadOrigin from '@/models/LeadOrigin';
import LeadType from '@/models/LeadType';
import Campaign from '@/models/Campaign';
import CMSUser from '@/models/CMSUser';
import { DynamicObject } from '@/interfaces/general/DynamicObject';
import { EntityTypes } from '@/enums/models/EntityTypes';
import { UserRepository } from '@/repositories/UserRepository';
import { StateSwitchActions } from '@/enums/global/StateSwitchActions';
import Label from '@/models/Label';
import PriceType from '@/models/PriceType';
import PriceListRegion from '@/models/PriceListRegion';
import { AxiosResponse } from 'axios';
import LeadProduct from '@/models/LeadProduct';
import ProjectSearch from '@/models/ProjectSearch';
import { validParameters } from '@/regex/validProjectSearchParameters';

export function constructUrlParams({
    searchQuery,
    entityType,
    availableFilters,
    results,
    page,
    sortField,
    sortOrder,
}: FilterUrl) {
    let query = '&q=(state:';
    query = updateQueryWithEntityType(entityType, query, availableFilters);

    const mandatoryFilterGroups: Record<string, string[]> = {};
    const standardFilterQueries: string[] = [];
    const urlParams: string[] = [];
    let dateRangeQuery = '';

    availableFilters
        .filter((filter: SearchEntityFilter) => {
            return (
                filter.isMandatory &&
                validateFilterValue(filter.value as any) &&
                validParameters.includes(filter.filter)
            );
        })
        .forEach((filter: SearchEntityFilter) => {
            if (filter.type === ElementFilterType.StateSwitch) {
                return;
            }
            const {
                partialMandatoryFilterQueries,
                partialUrlParams,
                partialDateRangeQuery,
                partialStandardFilterQueries,
            } = generateQueryPartBasedOnType(filter, entityType);

            if (partialDateRangeQuery !== '') {
                dateRangeQuery = partialDateRangeQuery;
            }
            urlParams.push(...partialUrlParams);

            if (partialMandatoryFilterQueries.length > 0) {
                if (!mandatoryFilterGroups[filter.filter]) {
                    mandatoryFilterGroups[filter.filter] = [];
                }
                mandatoryFilterGroups[filter.filter].push(...partialMandatoryFilterQueries);
            }

            if (partialStandardFilterQueries) {
                standardFilterQueries.push(...partialStandardFilterQueries);
            }
        });

    const groupedMandatoryQueries = Object.values(mandatoryFilterGroups)
        .filter((group) => group.length > 0)
        .map((group) => `(${group.join('|')})`);

    if (groupedMandatoryQueries.length > 0) {
        if (query !== '&q=') {
            query += `^`;
        }
        query += `(${groupedMandatoryQueries.join('^')})`;
    }

    if (standardFilterQueries.length) {
        standardFilterQueries.forEach((filter: string) => {
            query += `&${filter}`;
        });
    }

    const checkedFilters = availableFilters.filter((filter: SearchEntityFilter) => {
        return filter.type === ElementFilterType.Switch && filter.isMandatory == null && searchQuery && filter.value;
    });
    const filters = checkedFilters
        .map((filter: SearchEntityFilter) => {
            if (filter.type === ElementFilterType.Switch && filter.isMandatory == null) {
                const filterName = filter.filter.replace(/\d+/, '');
                let filterValue;

                if (filterName.toLocaleUpperCase().includes('ID')) {
                    filterValue = encodeURIComponent(`${searchQuery}%`);
                } else {
                    filterValue = encodeURIComponent(`%${searchQuery}%`);
                }

                if (filterName === 'typeSpecificId') {
                    const correctOffersNameEntity = entityType === ProjectTypes.Request ? 'offers.name' : 'offerName';

                    if (entityType === ProjectTypes.Offer) {
                        return [
                            `id:${encodeURIComponent(`${searchQuery}`)}`,
                            `${correctOffersNameEntity}:${filterValue}`,
                        ];
                    }
                    if (entityType === ProjectTypes.Order) {
                        return [`orderNumber:${filterValue}`, `${correctOffersNameEntity}:${filterValue}`];
                    } else {
                        return [
                            `id:${encodeURIComponent(`${searchQuery}`)}`,
                            `orderNumber:${filterValue}`,
                            `${correctOffersNameEntity}:${filterValue}`,
                        ];
                    }
                }

                return `${filterName}:${filterValue}`;
            }
        })
        .flat()
        .join('|');
    if (filters !== '') {
        if (query !== '&q=') {
            query += `^`;
        }
        query += `(${filters})`;
    }
    query += dateRangeQuery;

    if (sortField && sortOrder) {
        const isClientsTab = entityType === EntityTypes.CLIENTS;
        const sortDirection = sortOrder === 'ascend' ? '' : '-';
        let sortParam = `&sort=${sortDirection}${sortField.replace(/\d+/, '').replace('offerRevisions', 'offers')}`;

        if (!isClientsTab) {
            if (sortField.includes('country.name')) {
                sortParam = `&${sortDirection}sortByCountryName`;
            }
            if (sortField.includes('orderStatus.name')) {
                sortParam = `&${sortDirection}sortByOrderStatus`;
            }
            if (sortField === 'projectLabelName') {
                sortParam = `&sort=${sortDirection}projectLabelId`;
            }
        } else {
            if (sortField.includes('country.name')) {
                sortParam = `&sort=${sortDirection}address.country.id`;
            }
        }
        query += sortParam;
    }

    query += `&page[number]=${page}&page[size]=${results}`;

    if (entityType === ProjectTypes.Admin) {
        query += '&custom_filter[assignedUser]=null';
    }
    if (entityType === ProjectTypes.Request) {
        urlParams.push('sharedRequest');
    }
    if (urlParams.length > 0) {
        query += `&${urlParams.join('&')}`;
    }

    return query;
}

function validateFilterValue(value: string | number | Array<any>) {
    if (typeof value === 'string') {
        return !!value;
    }

    if (Array.isArray(value)) {
        return !!value.length && value.some((val: any) => !!val || val === 0);
    }

    return true;
}

export function extractNestedProperty(nestedProperties: string, record: any) {
    const keys = nestedProperties.split('.');
    if (keys.length === 1) {
        return record[nestedProperties];
    }
    let value = record;
    for (const key of keys) {
        if (value == null) {
            return null;
        }
        value = value[key];
    }
    return value;
}

export async function handleAndCleanUpSearchingForEntity(
    searchParameters: FilterUrl,
    currentUserId: string,
    shouldSaveToLocalStorage = true,
    isClientProjects = false,
    fromFilter?: string
) {
    let query = constructUrlParams(searchParameters);

    if (fromFilter) {
        query += '&includeAdditional=' + fromFilter;
    }

    try {
        await searchForCorrectEntity(searchParameters.entityType, query);
    } catch (e) {
        throw e;
    }

    EventBus.$emit(EventBusEvents.emitProjectPagination, {
        currentPage: searchParameters.page,
        pageSize: searchParameters.results,
    });

    if (shouldSaveToLocalStorage) {
        saveSearchParameters(
            {
                ...searchParameters,
                userId: currentUserId,
            },
            isClientProjects
        );
    }

    EventBus.$emit(EventBusEvents.searchFinished);
}

async function searchForCorrectEntity(projectType: string, query: string) {
    if (projectType === 'clients') {
        await Client.filterClients(query);
    } else if (projectType === ProjectTypes.Request) {
        await Project.getFilteredProjects(query, projectType);
    } else {
        await ProjectSearch.getAll(query);
    }
}

export function saveSearchParameters(searchParameters: FilterUrl, isClientProjects = false) {
    searchParameters.v = GlobalOptions.FILTERS_VERSION;
    let keyName = isClientProjects
        ? `clientsProjects-${searchParameters.entityType}`
        : `${searchParameters.entityType}`;
    if (searchParameters.userId) {
        keyName += `-${searchParameters.userId}`;
    }

    updateOtherFiltersSearchQuery(searchParameters, isClientProjects);
    LocalStorageService.save(keyName, JSON.stringify(searchParameters));
}

function updateOtherFiltersSearchQuery(searchParameters: FilterUrl, isClientProjects: boolean) {
    const user = UserRepository.getUserById(searchParameters.userId);

    if (user == null) {
        return;
    }

    if (!isClientProjects && searchParameters.entityType !== 'clients') {
        const filteredProjectTypes = Object.values(ProjectTypes).filter(
            (projectType) => projectType !== searchParameters.entityType
        );

        filteredProjectTypes.forEach((projectType) => {
            updateFilteredProjectTypeInLocalStorage(user, projectType as string, searchParameters.searchQuery);
        });
    }
}

function updateFilteredProjectTypeInLocalStorage(user: User, projectType: string, searchQuery: string | undefined) {
    if (!checkIfLocalStorageParametersExist(user, projectType as string)) {
        return;
    }

    const localStorageKey = `${projectType}-${user.id}`;
    const localStorageParameters = JSON.parse(LocalStorageService.get(localStorageKey) as string);

    localStorageParameters.searchQuery = searchQuery;

    LocalStorageService.save(localStorageKey, JSON.stringify(localStorageParameters));
}

export function clearSearchParameters(selectedProjectType: string, currentUserId: string) {
    if (LocalStorageService.has(`${selectedProjectType}-${currentUserId}`)) {
        LocalStorageService.remove(`${selectedProjectType}-${currentUserId}`);
    }
}

export function generateAndStoreSearchParameters(predefinedCustomSearchParameters: PredefinedCustomSearchParameters) {
    if (predefinedCustomSearchParameters.currentUser == null) {
        return null;
    }

    const searchParameters: FilterUrl = generateSearchParameters(predefinedCustomSearchParameters);

    if (predefinedCustomSearchParameters.shouldSetActiveTab) {
        changeLastSelectedTabToChosenType(predefinedCustomSearchParameters.type);
    }

    saveSearchParameters({
        ...searchParameters,
        userId: predefinedCustomSearchParameters.currentUser.id,
    });
}

function generateSearchParameters(predefinedCustomSearchParameters: PredefinedCustomSearchParameters) {
    const availableFilters = getAppropriateFilterOptions({
        entityType: predefinedCustomSearchParameters.type,
        currentUser: predefinedCustomSearchParameters.currentUser,
        label: predefinedCustomSearchParameters.label,
        orderStatus: predefinedCustomSearchParameters.orderStatus,
        shouldSetDefaultUser:
            predefinedCustomSearchParameters.shouldSetDefaultUser != null
                ? predefinedCustomSearchParameters.shouldSetDefaultUser
                : true,
    });

    let sortField: undefined | string = 'sortDate';

    if (predefinedCustomSearchParameters.type === 'clients') {
        sortField = undefined;
    }

    return {
        isSearchActive: true,
        searchQuery: predefinedCustomSearchParameters.query,
        entityType: predefinedCustomSearchParameters.type,
        availableFilters,
        sortField,
        sortOrder: 'descend',
        results: 10,
        page: 1,
    };
}

function changeLastSelectedTabToChosenType(type: string) {
    LocalStorageService.save('lastSelectedTab', type);
}

export function setCorrectPaginationAndSorters(
    newSearchParameters: FilterUrl,
    localStorageParameters: FilterUrl | null,
    defaultSortField: string,
    defaultSortOrder: SortOrder,
    shouldOverrideSortParameters: boolean
) {
    if (newSearchParameters.results == null) {
        newSearchParameters.results = localStorageParameters ? localStorageParameters.results : 10;
    }
    if (newSearchParameters.sortOrder == null) {
        newSearchParameters.sortOrder =
            localStorageParameters && shouldOverrideSortParameters
                ? localStorageParameters.sortOrder
                : defaultSortOrder;
        newSearchParameters.sortField =
            localStorageParameters && shouldOverrideSortParameters
                ? localStorageParameters.sortField
                : defaultSortField;
    }

    if (newSearchParameters.page == null) {
        newSearchParameters.page = 1;
    }
}

export function checkIfLocalStorageParametersExist(user: User | null, entityType: string) {
    if (user == null || !LocalStorageService.has(`${entityType}-${user.id}`)) {
        return false;
    }
    const localStorageParameters =
        // @ts-ignore
        JSON.parse(LocalStorageService.get(`${entityType}-${user.id}`));

    const localStorageParametersVersion = localStorageParameters.v;

    if (localStorageParametersVersion !== GlobalOptions.FILTERS_VERSION) {
        return false;
    }

    return user.id === localStorageParameters.userId;
}

export async function fetchProjectFilterRelatedEntities(entityType: keyof typeof ProjectTypes) {
    let entitiesToFetch: Array<Promise<AxiosResponse | void>>;

    switch (entityType) {
        case ProjectTypes.Order:
            entitiesToFetch = [ClientType.getAll(), OrderStatus.getAll(), CMSUser.getAllOnlyUsers()];
            break;
        case ProjectTypes.Offer:
            entitiesToFetch = [ClientType.getAll(), Label.getAll(), CMSUser.getAllOnlyUsers()];
            break;
        case ProjectTypes.Admin:
            entitiesToFetch = [
                ClientType.getAll(),
                LeadOrigin.getAll(),
                LeadType.getAll(),
                Campaign.getAll(),
                CMSUser.getAll(),
            ];
            break;
        case ProjectTypes.Request:
            entitiesToFetch = [
                ClientType.getAll(),
                CMSUser.getAllFromAllUserGroups(),
                Label.getAllFromAllUserGroups(),
                PriceType.getAll(),
                PriceListRegion.getAll(),
            ];
            break;
        case ProjectTypes.All:
            entitiesToFetch = [
                OrderStatus.getAll(),
                ClientType.getAll(),
                LeadOrigin.getAll(),
                LeadType.getAll(),
                LeadProduct.getAll(),
                Campaign.getAll(),
                CMSUser.getAllOnlyUsers(),
                Label.getAll(),
            ];
            break;
        default:
            entitiesToFetch = [
                ClientType.getAll(),
                LeadOrigin.getAll(),
                LeadType.getAll(),
                Campaign.getAll(),
                CMSUser.getAllOnlyUsers(),
                Label.getAll(),
            ];
    }
    await Promise.all(entitiesToFetch);
    return Promise.resolve();
}

export async function fetchClientFilterRelatedEntities() {
    await Promise.all([ClientType.getAll(), CMSUser.getAllOnlyUsers(), LeadOrigin.getAll()]);
}

/**
 * Takes the multiple dropdown sources, assigns the key name to the id and converts everything to a flat array
 * @param list - The object with the multiple dropdown sources
 * @return - A flat array with the modified id values
 */
export function convertMultipleDropdownSourcesToArray(list: DynamicObject) {
    const actualValues: DynamicObject = Object.assign({}, list);
    const output: any[] = [];

    // iterates through each key/value pair and calls the repository function
    // updates the id with the key name to make generating the search query easier
    Object.entries(actualValues).forEach(([key, repositoryFunction]: [string, () => any[]]) => {
        const entityInstances = repositoryFunction();

        const updatedItems = entityInstances.map((instance: any) => {
            return {
                ...instance,
                id: `${key}-${instance.id}`,
            };
        });

        output.push(...updatedItems);
    });

    return output;
}

/**
 * Adds the necessary query parts to the existing query in order to filter projects properly
 * @param entityType - The type of the entity that determines the base filter
 * @param query - The existing query
 * @param filters - All of the available filters for the selected tab
 * @return - The query with the updated filter
 */
export function updateQueryWithEntityType(entityType: string, query: string, filters: SearchEntityFilter[] = []) {
    let queryCopy = query;

    switch (entityType) {
        case ProjectTypes.Lead:
            queryCopy = generateStateSwitchQuery(filters as SearchEntityFilter[], 'lead|state:draft|state:request');
            break;
        case ProjectTypes.Offer:
            queryCopy = generateStateSwitchQuery(filters as SearchEntityFilter[], 'offer|state:request');
            break;
        case ProjectTypes.Request:
            queryCopy += `request)`;
            break;
        case ProjectTypes.Order:
            queryCopy += `order)`;
            break;
        case ProjectTypes.Admin:
            queryCopy += `lead)`;
            break;
        case ProjectTypes.All:
        case EntityTypes.CLIENTS:
            queryCopy = '&q=';
            break;
    }

    return queryCopy;
}

/**
 * Constructs the output object with the properly assigned filter name/value pairs
 * @param filter - The filter configuration
 * @param entityType - The state of the project
 * @return - An object with mandatory filter queries, url params and date range queries
 */
export function generateQueryPartBasedOnType(filter: SearchEntityFilter, entityType: string) {
    const mandatoryFilterQueries: string[] = [];
    const standardFilterQueries: string[] = [];
    const filterName = convertFilterToAppropriateName(filter, entityType);
    const filterValue = convertValueToAppropriateFormat(filter);

    // if filterValue or filterName are null, the filter might be complex so name or value cant be determined
    if (filterValue == null || filterName == null) {
        const complexFilterSettings = convertComplexFiltersToQueryString(filter, entityType);

        return {
            partialMandatoryFilterQueries: complexFilterSettings[0],
            partialUrlParams: complexFilterSettings[1],
            partialDateRangeQuery: complexFilterSettings[2],
        };
    }
    if (filter?.isStandard) {
        standardFilterQueries.push(`filter[${filterName}]:${filterValue}`);
    } else {
        mandatoryFilterQueries.push(`${filterName}:${filterValue}`);
    }
    return {
        partialMandatoryFilterQueries: mandatoryFilterQueries,
        partialUrlParams: [],
        partialDateRangeQuery: '',
        partialStandardFilterQueries: standardFilterQueries,
    };
}

/**
 * Formats the value according to the filter type
 * @param filter - The filter configuration whose value needs to be formatted
 * @return - The properly formatted value. Returns null if the filter does not exist,
 * if it's complex or if it's an empty string
 */
export function convertValueToAppropriateFormat(filter: SearchEntityFilter) {
    if (filter.value === '') {
        return null;
    }

    switch (filter.type) {
        case ElementFilterType.Input:
            return encodeURI(`${filter.value}%`);
        case ElementFilterType.GroupedDropdown:
        case ElementFilterType.CountryPicker:
        case ElementFilterType.Dropdown:
            return filter.value;
        case ElementFilterType.Switch:
            if (filter.value) {
                return null;
            }
            return filter.value ? '1' : '0';
        case ElementFilterType.NumberInput:
            const numberValue = filter.value as number;
            if (numberValue < 0) {
                return null;
            }
            return numberValue + 1;
        default:
            return null;
    }
}

/**
 * Generates the filter name according to the filter type
 * @param filter - The filter configuration whose value needs to be formatted
 * @param entityType - The state of the project
 * @return - The generated filter name based on type. Returns null if the filter does not exist or if it's complex
 */
export function convertFilterToAppropriateName(filter: SearchEntityFilter, entityType: string) {
    const filterName = filter.filter.replace(/\d+/, '');
    const isRequestTab = entityType === ProjectTypes.Request;
    const isClientsTab = entityType === EntityTypes.CLIENTS;

    switch (filter.type) {
        case ElementFilterType.GroupedDropdown:
        case ElementFilterType.Switch:
        case ElementFilterType.Dropdown:
        case ElementFilterType.Input:
        case ElementFilterType.NumberInput:
            return filterName;
        case ElementFilterType.CountryPicker:
            return isRequestTab || isClientsTab ? `${filterName}.id` : filterName;
        default:
            return null;
    }
}

/**
 * Generates the query part for the complex filters
 * @param filter - The date filter configuration
 * @return - A 3-tuple with the generated mandatory filter queries, url params and date range query
 */
export function convertComplexFiltersToQueryString(
    filter: SearchEntityFilter,
    entityType: string
): [string[], string[], string] {
    if (!Array.isArray(filter.value) || filter.value.length <= 0) {
        return [[], [], ''];
    }
    const mandatoryFilterQueries = [];
    const urlParams = [];
    let dateRangeQuery = '';
    switch (filter.type) {
        case ElementFilterType.MultipleSelect:
            const queryString = convertMultipleSelectFilterToQueryString(filter, entityType);
            if (filter.multipleSelectType === 'or') {
                mandatoryFilterQueries.push(queryString);
            } else {
                urlParams.push(queryString);
            }
            break;
        case ElementFilterType.DateRange:
            dateRangeQuery = convertDateRangeFilterToQueryString(filter);
            break;
    }

    return [mandatoryFilterQueries, urlParams, dateRangeQuery];
}

/**
 * Generates the query part for the complex date range filter
 * @param filter - The date filter configuration
 * @return - The generated query with filter names and values
 */
export function convertDateRangeFilterToQueryString(filter: SearchEntityFilter) {
    const filterName = filter.filter.replace(/\d+/, '');
    const filterValue = filter.value as string[];

    const fromString = filterName + 'From';
    const toString = filterName + 'To';

    const normalizedFromDate = new Date(filterValue[0]);
    normalizedFromDate.setUTCHours(0);
    normalizedFromDate.setUTCMinutes(0);
    normalizedFromDate.setUTCSeconds(0);

    const normalizedToDate = new Date(filterValue[1]);
    normalizedToDate.setUTCHours(23);
    normalizedToDate.setUTCMinutes(59);
    normalizedToDate.setUTCSeconds(59);

    const fromDate = formatDate(normalizedFromDate, 'YYYY-MM-DD HH:mm:ss');
    const toDate = formatDate(normalizedToDate, 'YYYY-MM-DD HH:mm:ss');

    return `&${fromString}=${fromDate}&${toString}=${toDate}`;
}

/**
 * Generates the query part for the complex multiple select filter
 * @param filter - The multiple select filter configuration
 * @return - The generated query with filter names and values
 */
export function convertMultipleSelectFilterToQueryString(filter: SearchEntityFilter, entityType: string) {
    if (entityType !== ProjectTypes.Request) {
        if (filter.multipleSelectType === 'or') {
            return filter.value
                .map((value: string) => {
                    const extractedId = (/\d+/.exec(value) as RegExpExecArray)[0];
                    if (filter.filter === 'leadProducts') {
                        return `${filter.filter}=${extractedId}`;
                    }
                    return `${filter.filter}:${extractedId}`;
                })
                .join('|');
        } else {
            return `${filter.filter}=${filter.value}`;
        }
    } else {
        const filterName = filter.filter.replace(/\d+/, '');
        const filterValue = filter.value as string[];
        if (filter.multipleSelectType === 'or') {
            const mapped = filterValue
                .map((id) => {
                    const extractedLabel = (/[^-]*/.exec(id) as RegExpExecArray)[0];
                    const extractedId = (/\d+/.exec(id) as RegExpExecArray)[0];
                    const finalFilterName = filter.shouldIncludeKeyInId ? `${extractedLabel}.id` : filterName;
                    const finalFilterValue = filter.shouldIncludeKeyInId ? extractedId : id;
                    return `${finalFilterName}:${finalFilterValue}`;
                })
                .join('|');
            return `(${mapped})`;
        } else {
            return `${filterName}=${filterValue}`;
        }
    }
}

/**
 * Generates additional state filters based on the value of the state switch (if it exists)
 * @param filters - All of the filters for this tab
 * @param basicQuery - The default state query for a specific entity type
 * @return - The generated query string
 */
export function generateStateSwitchQuery(filters: SearchEntityFilter[], basicQuery: string) {
    const stateSwitch = filters.find((filter) => filter.type === ElementFilterType.StateSwitch);

    if (stateSwitch == null || !stateSwitch.value) {
        return `&q=(state:${basicQuery})`;
    }

    const stateSwitchQuery = stateSwitch.value
        ? stateSwitch.filter
              .split(',')
              .map((value: any) => `|state:${value}`)
              .join('')
        : '';

    if (stateSwitch.value && stateSwitch.stateSwitchAction === StateSwitchActions.SET) {
        return `&q=(${stateSwitchQuery.substring(1)})`;
    }

    return `&q=(state:${basicQuery}${stateSwitchQuery})`;
}
