
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
// import this to use default icons for buttons
import { getOrderPdfForPreview, updateOrCreateOrderPDF, getOrderConfigurationIdByLocale } from '@/helpers/OrderHelper';
import i18n from '@/i18n';
import { validateAndOrderOffer } from '@/helpers/OfferHelper';
import ExclamationMark from '@/icons/ExclamationMark.vue';
import VuePdfApp from 'vue-pdf-app';
import { PdfViewConfig } from '@/enums/global/PdfViewConfig';
// import this to use default icons for buttons
import 'vue-pdf-app/dist/icons/main.css';
import { getDatepickerLocale } from '@/helpers/LocaleHelper';
import Offer from '@/models/Offer';
import moment from 'moment';

@Component({
    name: 'PdfPreviewOrderPopup',
    components: {
        ExclamationMark,
        VuePdfApp,
        PdfViewConfig,
    },
})
export default class PdfPreviewOrderPopup extends Vue {
    @Prop({ required: true }) private popupData!: any;
    private isPendingRequest = true;
    private isPendingRequestOfConfirmation = false;
    private src: string = '';
    private filename: string = '';
    private showAllErrors: boolean = false;
    private errorList = [];
    private confirmWord: string = '';
    private pdfConfig = PdfViewConfig;
    private deliveryDate: string = '';
    private rawInput: string = '';

    public async mounted() {
        const pdfData = await getOrderPdfForPreview(this.popupData.selectedOfferId, i18n.locale);
        const file = new Blob([pdfData.file.data], { type: 'application/pdf' });
        this.filename = this.popupData?.pdfName;
        this.errorList = pdfData.pdf.data.errors;
        this.src = URL.createObjectURL(file);
        this.isPendingRequest = false;
    }
    private get hasErrors() {
        return this.errorList.length > 0;
    }

    private get locale() {
        return getDatepickerLocale();
    }

    private async confirmOrder() {
        if (this.confirmWord.toLowerCase() !== i18n.t('Narudžba').toLowerCase()) {
            this.$notification.error({
                message: this.$t('Greška') as string,
                description: this.$t('Please type the correct confirm word to create the offer.') as string,
            });
            return;
        }
        this.isPendingRequestOfConfirmation = true;
        let isSuccess = false;

        try {
            await Offer.updateDeliveryDate(this.popupData.selectedOfferId, this.deliveryDate);

            const orderConfigurationId = getOrderConfigurationIdByLocale(i18n.locale);
            await updateOrCreateOrderPDF(this.popupData.selectedOfferId, orderConfigurationId);

            await validateAndOrderOffer(this.popupData.selectedOfferId, this.popupData.client);
            isSuccess = true;
        } catch (e) {
            if ((e as any).code === 'ECONNABORTED') {
                this.$notification.error({
                    message: this.$t('Greška') as string,
                    description: this.$t(
                        'Poslužitelj trenutno nije dostupan: Potvrda narudžbe trenutno nije moguća. Molimo pokušajte ponovo kasnije.'
                    ) as string,
                });
            }
        } finally {
            this.isPendingRequestOfConfirmation = false;

            const showCelebrationToUG =
                String(this.$store.getters['jwtData/currentUserGroup']) != '1' &&
                String(this.$store.getters['jwtData/currentUserGroup']) != '2' &&
                String(this.$store.getters['jwtData/currentUserGroup']) != '3';

            EventBus.$emit(EventBusEvents.updateProject, {
                shouldShowPopup: isSuccess && showCelebrationToUG,
                popupType: 'celebration',
            });
            EventBus.$emit(EventBusEvents.closePopup);
        }
    }

    private disabledDate(current: any) {
        const today = moment().startOf('day');
        const threeWeeksFromToday = moment().add(21, 'days').endOf('day');
        const oneYearFromToday = moment().add(1, 'year').endOf('day');

        return current && (current < today || current < threeWeeksFromToday || current > oneYearFromToday);
    }

    private async onOpenChange(isOpen: boolean) {
        await this.$nextTick();

        if (isOpen) {
            // Attach blur listener to the input field when the picker opens
            this.$nextTick(() => {
                const inputField = document.querySelector('.ant-calendar-input-wrap input') as HTMLInputElement;

                if (inputField) {
                    // reset value
                    this.rawInput = '';
                    inputField.addEventListener('input', this.captureRawInput);
                    inputField.addEventListener('blur', this.validateOnBlur);
                }
            });
        } else {
            // Remove the blur listener when the picker closes
            const inputField = document.querySelector('.ant-calendar-input-wrap input');
            if (inputField) {
                this.validateOnBlur();
                inputField.removeEventListener('input', this.captureRawInput);
                inputField.removeEventListener('blur', this.validateOnBlur);
            }
        }
    }

    private validateOnBlur() {
        const parsedDate = moment(this.rawInput, this.locale.lang.dateFormat, true); // Strict parsing

        const threeWeeksFromToday = moment().add(21, 'days').endOf('day');

        if (parsedDate.isBefore(threeWeeksFromToday)) {
            this.$notification.warning({
                message: this.$t('Upozorenje') as string,
                description: this.$t('"Date should be at least 3 weeks from today') as string,
            });
        }
    }

    private captureRawInput(event: any) {
        this.rawInput = event.target.value; // Save the raw input value
    }

    private onCancel() {
        EventBus.$emit(EventBusEvents.closePopup);
    }

    private editOffer() {
        EventBus.$emit(EventBusEvents.closePopup);
        const element = document.querySelector('[data-test="offer-copy"]') as HTMLElement;
        element && element?.click();
    }
}
