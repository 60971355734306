import { Model } from '@vuex-orm/core';
import MontageAPI from '@/api/MontageAPI';
import { MontageInterface } from '@/models/interfaces/Montage';
import { MontageDetailsOptions } from '@/interfaces/components/projectNew/MontageDetailsOptions';
import Offer from '@/models/Offer';

export default class Montage extends Model {
    public get getId() {
        // @ts-ignore
        return this.id;
    }

    public get getPrice() {
        // @ts-ignore
        return this.price;
    }

    public get getElectrician() {
        // @ts-ignore
        return this.electrician;
    }

    public get getExpectedTime() {
        // @ts-ignore
        return this.expectedTime;
    }

    public get getNumberOfWorkers() {
        // @ts-ignore
        return this.numberOfWorkers;
    }

    public static entity = 'montage';

    public static fields() {
        return {
            id: this.attr(null),
            price: this.number(0),
            electrician: this.boolean(false),
            assembly: this.boolean(false),
            delivery: this.boolean(false),
            removal: this.boolean(false),
            deconstruction: this.boolean(false),
            expectedTime: this.number(0),
            numberOfWorkers: this.number(0),
            offer_id: this.attr(null),
            offer: this.belongsTo(Offer, 'offer_id'),
        };
    }

    public static async createNew(offerId: string, montageInfo: MontageInterface) {
        try {
            await MontageAPI.create(String(offerId), montageInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }

    public static async updateExisting(montageId: string, montageInfo: MontageInterface) {
        try {
            await MontageAPI.update(String(montageId), montageInfo);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }

    public static async deleteExisting(montageId: string | null) {
        if (montageId == null) {
            return Promise.reject();
        }
        try {
            await MontageAPI.delete(montageId);
        } catch (e) {
            return Promise.reject(e);
        }

        Montage.delete(montageId);
        return Promise.resolve();
    }

    public static async updateExistingAndSaveLocally(values: MontageDetailsOptions, montageId: string) {
        let montage;

        try {
            montage = await MontageAPI.update(montageId, values);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({
            data: montage.data,
        });

        return Promise.resolve();
    }

    public static async createNewAndSaveLocally(values: MontageDetailsOptions, offerId: string) {
        let montage;
        try {
            montage = await MontageAPI.create(offerId, values);
        } catch (e) {
            return Promise.reject(e);
        }

        await this.insertOrUpdate({ data: montage.data });

        // Manually update Offer to trigger reactivity
        // await Offer.insertOrUpdate({
        //     data: {
        //         id: offerId, // Ensure Offer exists
        //         montage: montage.data, // Attach newly created Montage
        //     },
        // });
        return Promise.resolve();
    }
    public assembly!: boolean;
    public delivery!: boolean;
    public removal!: boolean;
    public deconstruction!: boolean;
}
