
import { Component, Vue } from 'vue-property-decorator';
import ColorModuleHeader from '@/components/global/color-module/ColorModuleHeader.vue';
import ColorModuleTable from '@/components/global/color-module/ColorModuleTable.vue';
import ColorModuleRequest from '@/components/global/color-module/ColorModuleRequest.vue';
import ColorModuleResult from '@/components/global/color-module/ColorModuleResult.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import Color from '@/models/Color';
import { ColorData } from '@/interfaces/components/color/ColorData';
import {
    formatCustomColor,
    formatColorSearchQuery,
    FiltersConfiguration,
} from '@/helpers/ColorModule/ColorModuleHelper';
import { Color as ColorInfo } from '@/interfaces/components/color/Color';
import { ColorRequestConfig } from '@/interfaces/components/color/ColorRequestConfig';
import { ColorFilters } from '@/interfaces/components/color/ColorFilters';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { formatSelectedColorForCustomRequest } from '@/helpers/ColorModule/ColorModuleHelper';
import ColorModuleTitle from '@/components/global/color-module/ColorModuleTitle.vue';
import ColorModuleWelcomeModal from '@/components/global/popup/ColorModuleWelcomeModal.vue';

@Component({
    name: 'ColorModule',
    components: {
        ColorModuleHeader,
        ColorModuleTable,
        ColorModuleRequest,
        ColorModuleResult,
        ColorModuleTitle,
        ColorModuleWelcomeModal,
    },
})
export default class ColorModule extends Vue {
    private visible: boolean = false;
    private infoModalVisible: boolean = false;
    private checklistFieldPID: string = '';
    private fieldValue: string = '';
    private activeProductFormId: number = 0;
    private activeTab: string = 'color-search';
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private filters: ColorFilters = {
        colorGloss: { id: '', value: '' },
        colorSurface: { id: '', value: '' },
        colorQuality: { id: '', value: '' },
        colorEffect: { id: '', value: '' },
        colorSystem: { id: '', value: '' },
        colorCollection: { id: '', value: '' },
    };
    private requestForm: ColorRequestConfig = {
        colorName: '',
        colorProducer: '',
        powderID: '',
    };
    private validationErrors: { [key: string]: string } = {
        colorName: '',
        colorProducer: '',
        powderID: '',
    };
    private searchQuery: string = '';
    private filtersActive: boolean = false;
    private requestFiltersActive: boolean = false;
    private moduleLoaded = false;

    private get checklistPIDs() {
        switch (this.checklistFieldPID) {
            case 'p74018':
                return {
                    manualColorSelectionPID: 'p74022',
                    standardColorsDropdownPID: 'p74019',
                    nonStandardColorsDropdownPID: 'p74020',
                };
            case 'p1031':
                return {
                    manualColorSelectionPID: 'p1035',
                    standardColorsDropdownPID: 'p1032',
                    nonStandardColorsDropdownPID: 'p1033',
                };
            default:
                return {
                    manualColorSelectionPID: 'p74022',
                    standardColorsDropdownPID: 'p74019',
                    nonStandardColorsDropdownPID: 'p74020',
                };
        }
    }

    // $t('Color representations are based on RGB values and may differ from the original')
    private get requestColor() {
        return formatCustomColor(this.requestForm, this.filters);
    }

    private get currentCollection() {
        return this.filters.colorCollection.value;
    }

    private async openPopup(payload?: { checklistFieldPID: string; fieldValue: string; activeProductFormId: number }) {
        if (payload) {
            this.checklistFieldPID = payload.checklistFieldPID;
            this.fieldValue = payload.fieldValue;
            this.activeProductFormId = payload.activeProductFormId;
        }

        this.visible = true;

        if (!this.moduleLoaded) {
            this.loadModule();
        }
    }

    private async loadModule() {
        try {
            this.loadingOverlay.start();
            this.moduleLoaded = true;
            this.infoModalVisible = true;

            await this.fetchAndRegisterColorsData();
            this.setDefaultValues();
            await this.fetchColors();
        } catch {
            return Promise.reject();
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private closePopup() {
        this.visible = false;
        // this.moduleLoaded = false;
        // reset forms
        // this.requestForm = {
        //     colorName: '',
        //     colorProducer: '',
        //     powderID: '',
        // };
        // this.filters = {
        //     colorGloss: { id: '', value: '' },
        //     colorSurface: { id: '', value: '' },
        //     colorQuality: { id: '', value: '' },
        //     colorEffect: { id: '', value: '' },
        //     colorSystem: { id: '', value: '' },
        //     colorCollection: { id: '', value: '' },
        // };
    }

    private changeActiveTab(tab: string) {
        this.activeTab = tab;
    }

    private setFiltersActiveState(active: boolean) {
        if (this.activeTab === 'color-request') {
            this.requestFiltersActive = active;
            return;
        }

        this.filtersActive = active;
    }

    private clearFilters() {
        // check if filters are already cleared
        if (!Object.values(this.filters).some((filter) => filter.id || filter.value) && this.searchQuery === '') {
            return;
        }

        this.filters = {
            colorGloss: { id: '', value: '' },
            colorSurface: { id: '', value: '' },
            colorQuality: { id: '', value: '' },
            colorEffect: { id: '', value: '' },
            colorSystem: { id: '', value: '' },
            colorCollection: { id: '', value: '' },
        };

        this.searchQuery = '';
        this.fetchColors();
    }

    private selectColor(colorData: {
        color: ColorInfo;
        selectedPowder: string;
        isRoltekColor: boolean;
        groupName: string;
    }) {
        if (!colorData?.isRoltekColor) {
            const formattedColor = formatSelectedColorForCustomRequest(colorData.color);
            this.sendCustomColorRequest(formattedColor);

            return;
        }

        const colorDropdownPID =
            colorData.groupName === 'STANDARD'
                ? this.checklistPIDs.standardColorsDropdownPID
                : this.checklistPIDs.nonStandardColorsDropdownPID;
        const fieldGroup = colorData.groupName === 'STANDARD' ? 'STANDARD' : 'NESTANDARDEN RAL';

        const payload = {
            activeProductFormId: this.activeProductFormId,
            fieldToBeUpdatedPID: this.checklistFieldPID,
            colorDropdownPID: colorDropdownPID,
            fieldValue: fieldGroup,
            selectedColorValue: colorData.color.ingeniousColor,
        };

        this.emitColorChange(payload);
        this.closePopup();
    }

    private sendCustomColorRequest(selectedColor: string = '') {
        const validateFields = this.validateRequestForm();

        if (!validateFields && this.activeTab === 'color-request') {
            this.$notification.error({
                message: 'Validation Error',
                description: Object.values(this.validationErrors)
                    .filter((val) => !!val)
                    .join('\n'),
            });
            return;
        }

        const payload = {
            activeProductFormId: this.activeProductFormId,
            fieldToBeUpdatedPID: this.checklistFieldPID,
            colorDropdownPID: this.checklistPIDs.manualColorSelectionPID,
            fieldValue: this.fieldValue,
            selectedColorValue: selectedColor || this.requestColor,
        };

        this.emitColorChange(payload);
        this.closePopup();
    }

    private emitColorChange(payload: any) {
        if (this.activeProductFormId === null) {
            EventBus.$emit(EventBusEvents.updateMultipositionHeaderSystemColorValue, payload);
        } else {
            EventBus.$emit(EventBusEvents.updateSystemColorValue, payload);
        }
    }

    private validateRequestForm() {
        this.validationErrors.colorName = this.requestForm.colorName
            ? ''
            : (this.$t('Color name is required!') as string);

        if (!this.requestForm.colorProducer && !this.requestForm.powderID) {
            this.validationErrors.colorProducer = (this.$t('Color producer is required!') +
                ' ' +
                this.$t('or')) as string;
            this.validationErrors.powderID = this.$t('Manufacturer’s powder ID is required!') as string;
        } else {
            this.validationErrors.colorProducer = '';
            this.validationErrors.powderID = '';
        }

        return !Object.values(this.validationErrors).some((error) => error);
    }

    private onFilterChange({ value, type, id }: { value: string; type: keyof ColorFilters; id: string }) {
        this.filters[type] = { id, value };

        if (this.activeTab === 'color-search') {
            this.fetchColors();
        }
    }

    private searchColors({ query }: { query: string }) {
        this.searchQuery = query;
    }

    private async fetchColors() {
        try {
            this.loadingOverlay.start();

            const query = formatColorSearchQuery(this.filters, '');

            const colors = await Color.getAll(query);
            this.$store.dispatch('colorData/setColorsData', { colors });
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private async fetchAndRegisterColorsData() {
        const requests: Promise<ColorData>[] = [
            // Color.getAll(),
            Color.getPowders(),
            Color.getPowderPrices(),
            Color.getSurfaces(),
            Color.getQualities(),
            Color.getEffects(),
            Color.getColorCharts(),
            Color.getColorCollections(),
        ];

        try {
            const results = await Promise.allSettled(requests);
            const [powders, prices, surfaces, qualities, effects, charts, collections] = results.map((result) =>
                result.status === 'fulfilled' ? result.value : []
            );

            this.$store.dispatch('colorData/setColorsData', {
                powders,
                prices,
                surfaces,
                qualities,
                effects,
                charts,
                collections,
            });
        } catch (error) {
            console.error(error);
        }
    }

    private setDefaultValues() {
        FiltersConfiguration.forEach((filter) => {
            if (filter?.defaultValue) {
                const selectedOption = filter.options().find((option: any) => option.id === filter.defaultValue);
                const value = selectedOption?.value || selectedOption?.groupName || '';
                this.filters[filter.type as keyof ColorFilters] = { id: selectedOption.id, value };
            }
        });
    }

    private async mounted() {
        EventBus.$on(EventBusEvents.showColorModule, this.openPopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.showColorModule, this.openPopup);
    }
}
