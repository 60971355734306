
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'ColorModuleTitle',
    components: {},
})
export default class ColorModuleTitle extends Vue {
    @Prop({ required: false, default: false }) private activeTab!: string;
}
